import React, { useEffect, useState } from 'react';
import { authenticationSuccessEventService, userService } from '../../_services'
import moment from 'moment'

function AuthenticationSuccessEvent() {

	const [events, setEvents] = useState([]);
	const [users, setUsers] = useState([]);

    useEffect(() => {
		authenticationSuccessEventService.getLastAuthenticationSuccessEvent().then(data => {
			setEvents(data)
        })
        userService.getAll().then(data => {
            setUsers(data)
        })
    }, [])

    const getUsername = (userId) => {
        var user = users.find(user => user.id == userId)
        if (user) {
            return user.username
        }
        return '-'
    }

	return (
		<div>
			<table className="table">
				<tbody>
					{ events.map(event => {
						return <tr key={event.id}>
							<td>
								{getUsername(event.id)}
							</td>
							<td>
								{moment(event.date).format('yyyy-MM-DD HH:mm:ss')}
							</td>
						</tr>
					})}
				</tbody>
			</table>
        </div>
    )
}

export default AuthenticationSuccessEvent;