import React from 'react'
import DataResult from '../dataresult';
import { useParams } from "react-router-dom";
import logo from "../../images/ssrs-black-logo.png";

function Dashboard() {

    let { id } = useParams();

    return (
        <>
            <DataResult 
                interval={"-8h"} 
                resolution={"1h"} 
                sensor={id}
                header={
                    <div>
                        <h3>Evedal - RS Växjö</h3>
                    </div>
                }
                description={
                <div>
                    <p>
                        Tillsammans med Sjöräddning i Växjö mäter IQMTEL temperaturen i Helgasjön (Räddningsstation Evedal i Växjö).
                        Detta är den första mätstationen av flera som kommer kopplas upp via Wexnets Smarta sensornät för att mäta vattentemperaturen.
                    </p>
                    <p>
                    För mer information kontakta IQMTEL <br />
                    Tel: <a href="tel:0470-500500">0470-500500</a> <br />
                    Epost: <a href="mailto:info@iqmtel.com">info@iqmtel.com</a> <br />
                    </p>
                </div>
                }
                footer={<img src={logo} style={{maxWidth: "200px"}}/>}
                dataKey={"temperature"}
                postfix={"℃"}
            />
        </>
    )
}

export default Dashboard;