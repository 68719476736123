import React from 'react';
import Login from './components/login.component'
import DefaultContainer from './components/default.component'
import {
  Route,
  Switch,
  Router
} from 'react-router-dom';
import { history } from './_helpers';
import Dashboard from './components/dashboard'

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/dashboard/:id"  component={Dashboard}/>
        <Route component={DefaultContainer} />   
      </Switch>
    </Router> 
  );
}

export default App;