import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { productService } from '../../_services'

const CustomerForm = (props) => {

    const [state, setState] = useState(props.data)
    const [products, setProducts] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        setState(props.data)
        if (products.length == 0) {
            productService.getAll().then(products => {
	            setProducts(products)
	        })
        }
    }, [props])

    const handleProductChange = (e) => {
		const target = e.target

	    if (target.checked) {
	        var product = products.find(product => product.id == target.value)
	        state.products.push(product)
	    } else {
	        state.products = state.products.filter(product => product.id != target.value)
	    }
	    handleChange({target: {type: "object", name: "products", value: state.products}})
	    console.log(state.products)
//	    setState( prevState => ({
//	        ...prevState,
//	        [products]: state.products
//	    }));
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('customer.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('customer.columns.name')}  name="name" value={state.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t('customer.columns.products')}
                </Form.Label>
                { products.map(product => {
                    return <Form.Check key={product.id} id={product.id} type="checkbox" label={product.name} value={product.id} checked={state.products.some(p => p.id == product.id)} onChange={handleProductChange}/>
                })}
            </Form.Group>
        </Form>
    )
}


export default CustomerForm