import React from 'react';
import CrudTable from '../table/crudtable'
import { actionConfigurationService } from '../../_services'
import ActionConfigurationForm from './actionconfigurationform.component'
import { useTranslation } from "react-i18next";

function ActionConfiguration() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		name: '',
		active: true,
		repeatable: false,
		actionConfigurationSensors: [],
		conditions: [{type: 'VALUE', params: {result_type: 'temperature', operator: 'GREATER_THAN', value: 0}}],
		actions: [{type: 'EMAIL', params: {address: '', subject: '', text: ''}}]
	}

	const formatActionConfigurationSensors = function(cell) {
		if (cell && cell.length > 0) {
			return cell.map(a => a.sensorName).sort((a, b) => a.localeCompare(b)).join(', ')
		}
		return '-'
	}

	const formatBoolean = function(cell) {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const columns = [
		{
			dataField: 'name',
			text: t('actionconfiguration.columns.name'),
			sort: true
		},
		{
			dataField: 'actionConfigurationSensors',
			text: t('actionconfiguration.columns.sensors'),
			formatter: formatActionConfigurationSensors
		},
		{
			dataField: 'active',
			text: t('actionconfiguration.columns.active'),
			formatter: formatBoolean,
			sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('actionconfiguration.label')}
      </h2>
      <CrudTable
        service={actionConfigurationService}
        columns={columns}
        initialState={initialState}
        form={<ActionConfigurationForm />}
        sort={{dataField: 'name', order: 'asc'}}
        size="xl"
      />
    </div>
  )
}

export default ActionConfiguration;