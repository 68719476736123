import api from './api.service'

export const nodeService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/nodes/'

function getAll() {
    return api.get(BASE_URL, {sort: 'name', order: 'asc'})
}

function save(node) {
    return api.post(BASE_URL, node)
}

function update(id, node) {
	let tmp = {...node}
	delete tmp.children
	delete tmp.sensors
	delete tmp.tempChildren
    return api.put(BASE_URL + id, tmp)
}

function _delete(id) {
    return api.delete(BASE_URL + id)
}
