import React from 'react'
import Home from './home.component'
import Device from './device'
import DeviceMetadata from './devicemetadata'
import Result from './result'
import DataExplorer from './dataexplorer'
import PeopleCounter from './peoplecounter'
import Lifebuoy from './lifebuoy'
import SensorMap from './sensormap'
import Node from './node'
import SensorIcon from './sensoricon'
import Category from './category'
import User from './user'
import DeviceModelComponent from './devicemodel'
import Customer from './customer/customer'
import AccessTag from './accesstag'
import ApiCredential from './apicredential'
import DomainChangeLog from './domainchangelog'
import ChangeLog from './changelog'
import CustomerAccessTag from './customeraccesstag'
import ActionConfiguration from './actionconfiguration'
import ActionEvent from './actionevent'
import DownlinkTest from './downlinktest'
import Export from './export'
import AppLogList from './apploglist'
import AppLogMap from './applogmap'
import AuthenticationSuccessEvent from './authenticationsuccessevent'
import Quartz from './quartz'
import SwitchUser from './switchuser'
import SwitchBack from './switchback'
import Logout from './logout.component'
import Forbidden from './forbidden.component'
import { ProtectedRoute } from "./protected.route";
import PageNavbar from "./navbar.component"
import AlertComponent from './alert.component'
import { Switch, Route } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap'

function DefaultContainer() {
    return (
      <>
      <PageNavbar />
      <Container fluid>
        <Col md={{ span: 12, offset: 0 }}>
          <AlertComponent />
          <Switch>
              <ProtectedRoute exact path="/" component={Home}/>
              <ProtectedRoute exact path="/map" component={SensorMap} permission={["ROLE_SENSOR_READ"]}/>
              <ProtectedRoute exact path="/node" component={Node} permission={["ROLE_NODE_READ"]}/>
              <ProtectedRoute exact path="/sensoricon" component={SensorIcon} permission={["ROLE_SENSOR_ICON_READ"]}/>
              <ProtectedRoute exact path="/category" component={Category} permission={["ROLE_CATEGORY_READ"]}/>
              <ProtectedRoute exact path="/device" component={Device} permission={["ROLE_DEVICE_READ"]}/>
              <ProtectedRoute exact path="/devicemetadata" component={DeviceMetadata} permission={["ROLE_DEVICE_METADATA_READ"]}/>
              <ProtectedRoute exact path="/result" component={Result} permission={["ROLE_RESULT_READ"]}/>
              <ProtectedRoute exact path="/dataexplorer" component={DataExplorer} permission={["ROLE_RESULT_READ"]}/>
              <ProtectedRoute exact path="/peoplecounter" component={PeopleCounter} permission={["ROLE_PEOPLE_COUNTER_USER"]}/>
              <ProtectedRoute exact path="/lifebuoy" component={Lifebuoy} permission={["ROLE_LIFEBUOY_USER"]}/>
              <ProtectedRoute exact path="/user" component={User} permission={["ROLE_SUPER_ADMIN", "ROLE_CUSTOMER_ADMIN"]}/>
              <ProtectedRoute exact path="/customer" component={Customer} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/accessTag" component={AccessTag} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/apiCredential" component={ApiCredential} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/domainChangeLog" component={DomainChangeLog} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/changeLog" component={ChangeLog} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/customerAccessTag" component={CustomerAccessTag} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/devicemodel" component={DeviceModelComponent} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/actionconfiguration" component={ActionConfiguration} permission={["ROLE_ACTION_CONFIGURATION_READ"]}/>
              <ProtectedRoute exact path="/actionEvent" component={ActionEvent} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/downlinkTest" component={DownlinkTest} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/export" component={Export} permission={["ROLE_ADMIN"]}/>
              <ProtectedRoute exact path="/appLogList" component={AppLogList} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/appLogMap" component={AppLogMap} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/authenticationSuccessEvent" component={AuthenticationSuccessEvent} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/switchuser" component={SwitchUser} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/switchback" component={SwitchBack} permission={["ROLE_SWITCHED_USER"]}/>
              <ProtectedRoute exact path="/quartz" component={Quartz} permission={["ROLE_SUPER_ADMIN"]}/>
              <Route exact path="/logout" component={Logout}/>
              <Route exact path="/forbidden" component={Forbidden}/>
              <Route path="*" component={() => "404 NOT FOUND"}/> 
          </Switch>
        </Col>
      </Container>
      </>
    )
}

export default DefaultContainer;