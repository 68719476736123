import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { customerService, roleService, authService } from '../../_services'

const UserForm = ({data, handleChange, handleSubmit, validated}) => {
    const [customers, setCustomers] = useState([])
    const [selectedRole, setSelectedRole] = useState('NO_ROLE')
    const [roles, setRoles] = useState([])

    const {t} = useTranslation('common');

    useEffect(() => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            customerService.getAll().then(customers => {
	            setCustomers(customers)
	            if (data.customer === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customer", value: customers[0].id}})
	            }
	        })
        } else {
            setTimeout(() => {
                handleChange({target: {type: "select", name: "customer", value: authService.getCurrentUser().customer_id}})
            }, 100)
        }
        roleService.getAll().then(roles => {
            //setSelectedValues(data.userRoles.map(userRole => ({id: userRole.role, authority: roles.find(role => role.id === userRole.role)}.authority)))
            setRoles(roles)
        })
        if (data.userRoles && data.userRoles.length > 0) {
            setSelectedRole(data.userRoles[0].authority)
        }
    }, [])

    function onChange(e) {
		if (e.target.value != 'NO_ROLE') {
			var role = roles.find(role => role.authority == e.target.value)
			if (role) {
				handleChange({target: {type: "select", name: "userRoles", value: [{role: role.id}]}})
			}
		} else {
			handleChange({target: {type: "select", name: "userRoles", value: []}})
		}
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
	            <Form.Group controlId="customer">
	                <Form.Label>
	                    {t('user.columns.customer')}
	                </Form.Label>
	                <Form.Control required as="select" name="customer" value={data.customer} onChange={handleChange}>
	                    {customers.map(customer => (
	                        <option key={customer.id} value={customer.id}>{customer.name}</option>
	                    ))}
	                </Form.Control>
	            </Form.Group>
            }
            <Form.Group controlId="username">
                <Form.Label>
                    {t('user.columns.username')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('user.columns.username')} name="username" value={data.username} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>
                    {t('user.columns.password')}
                </Form.Label>
                <Form.Control required type="password" placeholder={t('user.columns.password')} name="password" value={data.password} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="roles">
                <Form.Label>
                    {t('user.columns.roles')}
                </Form.Label>
                <select className="form-control" onChange={onChange} value={selectedRole}>
                    <option value="NO_ROLE">{t('user.roles.norole')}</option>
                    { roles.some(role => role.authority == 'ROLE_SUPER_ADMIN') &&
                        <option value="ROLE_SUPER_ADMIN">{t('user.roles.superadmin')}</option>
                    }
                    { roles.some(role => role.authority == 'ROLE_CUSTOMER_ADMIN') &&
                        <option value="ROLE_CUSTOMER_ADMIN">{t('user.roles.customeradmin')}</option>
                    }
                    { roles.some(role => role.authority == 'ROLE_ADMIN') &&
                        <option value="ROLE_ADMIN">{t('user.roles.admin')}</option>
                    }
                    { roles.some(role => role.authority == 'ROLE_USER') &&
                        <option value="ROLE_USER">{t('user.roles.user')}</option>
                    }
                    { roles.some(role => role.authority == 'ROLE_PEOPLE_COUNTER_USER') &&
                        <option value="ROLE_PEOPLE_COUNTER_USER">{t('user.roles.peoplecounteruser')}</option>
                    }
                </select>
            </Form.Group>
            <Form.Group controlId="enabled">
                <Form.Check type="checkbox" label={t('user.columns.enabled')} name="enabled" checked={data.enabled} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="accountExpired">
                <Form.Check type="checkbox" label={t('user.columns.accountexpired')} name="accountExpired" checked={data.accountExpired} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="accountLocked">
                <Form.Check type="checkbox" label={t('user.columns.accountlocked')} name="accountLocked" checked={data.accountLocked} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="passwordExpired">
                <Form.Check type="checkbox" label={t('user.columns.passwordexpired')} name="passwordExpired" checked={data.passwordExpired} onChange={handleChange}/>
            </Form.Group>
        </Form>
    )
}


export default UserForm