import React from 'react';
import CrudTable from '../table/crudtable'
import { apiCredentialService } from '../../_services'
import ApiCredentialForm from './apicredentialform.component'
import { useTranslation } from "react-i18next";

function ApiCredential() {

    const {t} = useTranslation('common');

	const initialState = {
		accessTag: '',
		type: '',
		params: {}
	}

    const formatParams = function(cell) {
        if (cell) {
        console.log(cell)
            return Object.entries(cell).map(([key, value]) => key + ': ' + value).join(', ')
        }
		return '-'
	}

	const columns = [
		{
			dataField: 'accessTagName',
			text: t('apicredential.columns.accesstag'),
			sort: true
		},
		{
			dataField: 'type',
			text: t('apicredential.columns.type'),
			sort: true
		},
		{
			dataField: 'params',
			text: t('apicredential.columns.params'),
			formatter: formatParams,
			sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('apicredential.label')}
      </h2>
      <CrudTable
        service={apiCredentialService}
        columns={columns}
        initialState={initialState}
        form={<ApiCredentialForm />}
        sort={{dataField: 'customer.name', order: 'asc'}}
      />
    </div>
  )
}

export default ApiCredential;