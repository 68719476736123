import React from 'react'
import { Navbar, Nav, Form, Button, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Access from './access.component';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { environmentService, authService } from '../_services'

function PageNavbar() {

	const {t, i18n} = useTranslation('common');

	function isActive(component) {
		return window.location.pathname.startsWith("/" + component)
	}

	const getUserName = () => {
		return <>
            <FontAwesomeIcon icon={faUser}/> {authService.getCurrentUser().username}
        </>
    }

    return (
        <Navbar bg="light" expand="lg">
	        <Navbar.Brand as={Link} to={"/"}>
	            <img src={environmentService.getLogo()} style={{height: 24}}/>
	        </Navbar.Brand>
	        <Navbar.Toggle aria-controls="basic-navbar-nav" />
	        <Navbar.Collapse id="basic-navbar-nav">
	            <Nav className="mr-auto">
	                <Access permission={["ROLE_USER"]}>
		                <Nav.Link as={Link} to={"node"} active={isActive("node")}>
		                    {t('navbar.node')}
		                </Nav.Link>
		            </Access>
		            <Access permission={["ROLE_USER"]}>
		                <Nav.Link as={Link} to={"map"} active={isActive("map")}>
		                    {t('navbar.map')}
		                </Nav.Link>
		            </Access>
		            <Access permission={["ROLE_USER"]}>
		                <Nav.Link as={Link} to={"category"} active={isActive("category")}>
		                    {t('navbar.category')}
		                </Nav.Link>
		            </Access>
		            <Access permission={["ROLE_USER"]}>
		                <Nav.Link as={Link} to={"sensorIcon"} active={isActive("sensorIcon")}>
		                    {t('navbar.sensoricon')}
		                </Nav.Link>
		            </Access>
		            <Access permission={["ROLE_USER"]}>
		                <Nav.Link as={Link} to={"result"} active={isActive("result")}>
		                    {t('navbar.result')}
		                </Nav.Link>
		            </Access>
		            <Access permission={["ROLE_USER"]}>
		                <Nav.Link as={Link} to={"dataexplorer"} active={isActive("dataexplorer")}>
		                    {t('navbar.dataexplorer')}
		                </Nav.Link>
		            </Access>
		            <Access permission={["ROLE_USER"]}>
	                    <Nav.Link as={Link} to={"device"} active={isActive("device")}>
	                        {t('navbar.device')}
	                    </Nav.Link>
	                </Access>
	                <Access permission={["ROLE_USER"]}>
	                    <Nav.Link as={Link} to={"devicemetadata"} active={isActive("devicemetadata")}>
	                        {t('navbar.devicemetadata')}
	                    </Nav.Link>
	                </Access>
	                <Access permission={["ROLE_ACTION_CONFIGURATION_READ"]}>
	                    <Nav.Link as={Link} to={"actionconfiguration"} active={isActive("actionconfiguration")}>
	                        {t('navbar.actionconfiguration')}
	                    </Nav.Link>
	                </Access>
	                <Access permission={["ROLE_PEOPLE_COUNTER_USER"]} products={["PEOPLE_COUNTER"]}>
	                    <Nav.Link as={Link} to={"peoplecounter"} active={isActive("peoplecounter")}>
	                        {t('navbar.peoplecounter')}
	                    </Nav.Link>
	                </Access>
	                <Access permission={["ROLE_LIFEBUOY_USER"]} products={["LIFEBUOY"]}>
	                    <Nav.Link as={Link} to={"lifebuoy"} active={isActive("lifebuoy")}>
	                        {t('navbar.lifebuoy')}
	                    </Nav.Link>
	                </Access>
	                <Access permission={["ROLE_ADMIN"]} products={["EXPORT"]}>
	                    <Nav.Link as={Link} to={"export"} active={isActive("export")}>
	                        {t('navbar.export')}
	                    </Nav.Link>
	                </Access>
	                <Access permission={["ROLE_SUPER_ADMIN"]}>
		                <NavDropdown title="Admin" id="basic-nav-dropdown">
		                    <NavDropdown.Item as={Link} to={"/customer"} active={isActive("customer")}>
		                        {t('navbar.customer')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/user"} active={isActive("user")}>
		                        {t('navbar.user')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/accessTag"} active={isActive("accessTag")}>
		                        {t('navbar.accesstag')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/customeraccesstag"} active={isActive("customeraccesstag")}>
		                        {t('navbar.customeraccesstag')}
		                    </NavDropdown.Item>
	                        <NavDropdown.Item as={Link} to={"/devicemodel"} active={isActive("devicemodel")}>
	                            {t('navbar.devicemodel')}
	                        </NavDropdown.Item>
	                        <NavDropdown.Item as={Link} to={"/apiCredential"} active={isActive("apiCredential")}>
	                            {t('navbar.apicredential')}
	                        </NavDropdown.Item>
	                        <NavDropdown.Item as={Link} to={"/domainChangeLog"} active={isActive("domainChangeLog")}>
		                        {t('navbar.domainchangelog')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/changeLog"} active={isActive("changeLog")}>
		                        {t('navbar.changelog')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/downlinkTest"} active={isActive("downlinkTest")}>
		                        Downlink Test
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/actionEvent"} active={isActive("actionEvent")}>
		                        {t('navbar.actionevent')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/appLogList"} active={isActive("appLogList")}>
		                        {t('navbar.apploglist')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/appLogMap"} active={isActive("appLogMap")}>
		                        {t('navbar.applogmap')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/authenticationSuccessEvent"} active={isActive("authenticationSuccessEvent")}>
		                        {t('navbar.authenticationsuccessevent')}
		                    </NavDropdown.Item>
		                    <NavDropdown.Item as={Link} to={"/quartz"} active={isActive("quartz")}>
		                        {t('navbar.quartz', 'Quartz')}
		                    </NavDropdown.Item>
		                </NavDropdown>
		            </Access>
		            <Access permission={["ROLE_CUSTOMER_ADMIN"]}>
		                <Nav.Link as={Link} to={"user"} active={isActive("user")}>
	                        {t('navbar.user')}
	                    </Nav.Link>
		            </Access>
	            </Nav>
	            { authService.isAuthenticated() &&
		            <Form inline>
		                <Nav>
			                <NavDropdown alignRight title={getUserName()} id="basic-nav-dropdown">
			                    <Access permission={["ROLE_SUPER_ADMIN"]}>
				                    <NavDropdown.Item as={Link} to={"/switchuser"}>
				                        {t('navbar.switchuser')}
				                    </NavDropdown.Item>
			                    </Access>
			                    <Access permission={["ROLE_SWITCHED_USER"]}>
					                <NavDropdown.Item as={Link} to={"/switchback"}>
				                        {t('navbar.switchback')}
				                    </NavDropdown.Item>
		                        </Access>
			                    <NavDropdown.Item as={Link} to={"/changeLog"}>
			                        {t('navbar.changelog')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/logout"}>
			                        {t('navbar.logout')}
			                    </NavDropdown.Item>
			                </NavDropdown>
		                </Nav>
		            </Form>
	            }
	        </Navbar.Collapse>
        </Navbar>
    )
}

export default PageNavbar;