import React, { useState, useEffect } from 'react'
import {Form} from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider';
import { useTranslation } from "react-i18next";
import { accessTagService } from '../../_services'

const SensorIconForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common')

    useEffect(() => {
		if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (state.accessTag == '') {
                    handleChange({target: {name: "accessTag", value: accessTags[0].id}})
                }
            })
		}
    }, [props])

    function onChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
                handleChange({target: {type: "file", name: "data", value: hexString}})
            });
            reader.readAsBinaryString(file)
        }
    }

    function onSlide(changeEvent) {
        handleChange({target: {name: "size", value: changeEvent.target.value}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="accessTag">
                <Form.Label>
                    {t('sensoricon.columns.accesstag')}
                </Form.Label>
                <Form.Control as="select" name="accessTag" value={state.accessTag} onChange={handleChange} required>
                    { accessTags.map(accessTag => {
                        return <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('sensoricon.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('sensoricon.columns.name')}  name="name" value={state.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="data">
                <Form.Label>
                    {t('sensoricon.columns.data')}
                </Form.Label>
                { state.data &&
                    <div className="text-center">
                        <img src={"data:image/png;base64, " + state.data} style={{maxWidth: state.size + 'px', maxHeight: state.size + 'px'}}/>
                    </div>
                }
                <RangeSlider
                    value={state.size}
                    min={16}
                    max={128}
                    step={2}
                    onChange={changeEvent => onSlide(changeEvent)}
                />
                <Form.Control required={!state.data} type="file" accept=".png" name="data" onChange={onChange} />
                <Form.Control.Feedback type="invalid">
                    {t('sensoricon.errors.datainvalid')}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}

export default SensorIconForm