import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const ChangeLogForm = (props) => {

    const [state, setState] = useState(props.data)
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        setState(props.data)
    }, [props])

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="versioncode">
                <Form.Label>
                    {t('changelog.columns.versioncode')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('changelog.columns.versioncode')}  name="versionCode" value={state.versionCode} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="description">
                <Form.Label>
                    {t('changelog.columns.description')}
                </Form.Label>
                <Form.Control required as="textarea" rows="5" placeholder={t('changelog.columns.description')}  name="description" value={state.description} onChange={handleChange} />
            </Form.Group>
        </Form>
    )
}

export default ChangeLogForm