import React from 'react';
import CrudTable from '../table/crudtable'
import { userService } from '../../_services'
import UserForm from './userform.component'
import { useTranslation } from "react-i18next";
import { authService } from '../../_services'

function User() {

	const {t} = useTranslation('common');

	const initialState = {
		customer: -1,
		username: '',
		password: '',
		enabled: true,
		accountExpired: false,
		accountLocked: false,
		passwordExpired: false,
		userRoles: []
    }

	const formatBoolean = function(cell) {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const formatUserRoles = function(cell) {
		if (cell && cell.length > 0) {
			var authorities = []
			cell.forEach(userRole => {
				switch (userRole.authority) {
					case 'ROLE_SUPER_ADMIN':
						authorities.push(t('user.roles.superadmin'))
						break;
					case 'ROLE_CUSTOMER_ADMIN':
						authorities.push(t('user.roles.customeradmin'))
						break;
					case 'ROLE_ADMIN':
						authorities.push(t('user.roles.admin'))
						break;
					case 'ROLE_USER':
						authorities.push(t('user.roles.user'))
						break;
					case 'ROLE_PEOPLE_COUNTER_USER':
						authorities.push(t('user.roles.peoplecounteruser'))
						break;
				}
			})
			if (authorities.length == 0) {
				return '-'
			}
			return authorities.join(', ')
		}
		return '-'
	}

	const hideCustomerName = function() {
		return !authService.hasRoles(['ROLE_SUPER_ADMIN'])
	}

	const columns = [
		{
			dataField: 'username',
			text: t('user.columns.username'),
			sort: true
		},
		{
			dataField: 'customerName',
			text: t('user.columns.customer'),
			sort: true,
			hidden: hideCustomerName()
		},
		{
			dataField: 'userRoles',
			text: t('user.columns.roles'),
			sort: true,
			formatter: formatUserRoles
		},
		{
			dataField: 'enabled',
			text: t('user.columns.enabled'),
			formatter: formatBoolean,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('user.label')}
			</h2>
			<CrudTable
				service={userService}
				columns={columns}
				initialState={initialState}
				form={<UserForm />}
				remote={{"filter": false, "search": false, "pagination": false}}
				sort={{dataField: 'username', order: 'asc'}}
			/>
		</div>
	)
}

export default User;