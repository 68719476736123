import React from 'react';
import CrudTable from '../table/crudtable'
import { actionEventService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function ActionEvent() {

    const {t} = useTranslation('common');

    const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const columns = [
		{
			dataField: 'dateCreated',
			text: t('actionevent.columns.datecreated'),
			formatter: formatDate,
			sort: true
		},
		{
			dataField: 'actionConfigurationName',
			text: t('actionevent.columns.actionconfiguration'),
			sort: true
		},
		{
			dataField: 'sensorName',
			text: t('actionevent.columns.sensor'),
			sort: true
		},
		{
			dataField: 'result',
			text: t('actionevent.columns.result'),
			sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('actionevent.label')}
      </h2>
      <CrudTable
        service={actionEventService}
        columns={columns}
        sort={{dataField: 'dateCreated', order: 'desc'}}
        creatable={false}
		editable={false}
		deletable={true}
      />
    </div>
  )
}

export default ActionEvent;