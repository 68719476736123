import React, {useState, useEffect} from 'react'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { accessTagService, nodeService, nodeTypeService, deviceService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'

const NodeForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const [nodes, setNodes] = useState([])
    const [nodeTypes, setNodeTypes] = useState([])
    const [devices, setDevices] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (state.accessTag == '') {
                    handleChange({target: {name: "accessTag", value: accessTags[0].id}})
                }
            })
		}
        if (nodes.length == 0) {
            nodeService.getAll().then(nodes => {
	            setNodes(getOptions(nodes))
	        })
        }
        if (nodeTypes.length == 0) {
            nodeTypeService.getAll().then(nodeTypes => {
	            setNodeTypes(nodeTypes)
	        })
        }
        if (devices.length == 0) {
            deviceService.getAll().then(devices => {
	            setDevices(devices)
	        })
        }
    }, [])

    const getOptions = (data) => {
        if (state.id) {
            var children = getChildren(data, state)
            var validNodes = data.filter(node => node.id != state.id && children.indexOf(node.id) < 0)
            return validNodes
        }
        return data
    }

    const getChildren = (nodes, node) => {
        var children = [...node.children]
		node.children.map(childId => {
			var child = nodes.find(g => g.id == childId)
			if (child) {
				var tmp = getChildren(nodes, child)
				if (tmp.length > 0) {
					children.push(...tmp)
				}
			}
		})
        return children
    }

    function addParam(e) {
        e.preventDefault()

        let newParams = {...state.params, '': ''}

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function removeParam(e, key) {
        e.preventDefault();

		let newParams = {...state.params}
        delete newParams[key]

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleKeyChange(e, key) {
        let targetValue = e.target.value

        var newParams = {}
        for (const [prevKey, prevValue] of Object.entries(state.params)) {
            console.log(prevKey + ': ' + prevValue)
            if (prevKey !== key) {
                newParams[prevKey] = prevValue
            } else {
                while (targetValue in state.params) {
                    targetValue = '_' + targetValue
                }
                newParams['' + targetValue] = prevValue
            }
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...state.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="accessTag">
                <Form.Label>
                    {t('node.columns.accesstag')}
                </Form.Label>
                <Form.Control as="select" name="accessTag" value={state.accessTag} onChange={handleChange} required disabled={state.id || state.parent}>
                    { accessTags.map(accessTag => {
                        return <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('node.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('node.columns.name')} name="name" value={state.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="description">
                <Form.Label>
                    {t('node.columns.description')}
                </Form.Label>
                <Form.Control type="text" placeholder={t('node.columns.description')} name="description" value={state.description} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="parent">
                <Form.Label>
                    {t('node.columns.parent')}
                </Form.Label>
                <Form.Control as="select" name="parent" value={state.parent} onChange={handleChange}>
                    <option key="null" value="null">{t('node.noparent')}</option>
                    { nodes.map(node => {
                        return <option key={node.id} value={node.id}>{node.name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('node.columns.type')}
                 </Form.Label>
                <Form.Control as="select" name="type" value={state.type} onChange={handleChange}>
                    { nodeTypes.map(nodeType => {
                        return <option key={nodeType} value={nodeType}>{nodeType}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="params">
                <Form.Label>
                    {t('node.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(state.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th style={{width: '33%'}}>
	                                {t('node.params.key')}
	                            </th>
	                            <th style={{width: '67%'}}>
	                                {t('node.params.value')}
	                            </th>
	                            <th></th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(state.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									<Form.Control required type="text" placeholder={t('node.params.key')} name="key" value={key} onChange={(e) => handleKeyChange(e, key)} />
								</td>
								<td>
									<Form.Control required type="text" placeholder={t('node.params.value')} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
								</td>
	                            <td>
									<Button
			                            onClick={(e) => removeParam(e, key)}
			                            title="Delete"
			                            size="sm"
			                            color="info"
			                            variant="outline-danger">
			                            <FontAwesomeIcon icon={faTrash}/>
			                        </Button>
		                        </td>
							</tr>
		                })}
	                </tbody>
                </table>
                <Button
                    onClick={addParam}
                    title={t('node.params.addparam')}
                    color="info"
                    variant="outline-primary">
                    <FontAwesomeIcon icon={faPlus}/> {t('node.params.addparam')}
                </Button>
            </Form.Group>
        </Form>
    )
}

export default NodeForm