import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { accessTagService, categoryService, nodeService, sensorTypeService, deviceService, sensorIconService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faClipboard } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { GoogleMap, Marker } from '@react-google-maps/api';
import {SERVER_URL} from '../../config';

const SensorForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const [categories, setCategories] = useState([])
    const [nodes, setNodes] = useState([])
    const [sensorTypes, setSensorTypes] = useState([])
    const [devices, setDevices] = useState([])
    const [selectedDevices, setSelectedDevices] = useState([])
    const [sensorIcons, setSensorIcons] = useState([])
    const [map, setMap] = React.useState(null)
    const [newImage, setNewImage] = React.useState(null)
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (state.accessTag == '') {
                    handleChange({target: {name: "accessTag", value: accessTags[0].id}})
                }
            })
		}
		if (categories.length == 0) {
			categoryService.getAll().then(categories => {
                setCategories(categories)
            })
		}
        if (nodes.length == 0) {
            nodeService.getAll().then(nodes => {
	            setNodes(nodes)
	        })
        }
        if (sensorTypes.length == 0) {
            sensorTypeService.getAll().then(sensorTypes => {
	            setSensorTypes(sensorTypes)
	        })
        }
        if (devices.length == 0) {
            deviceService.getAll().then(devices => {
                setSelectedDevices(state.deviceRefs.map(deviceRef => ({id: deviceRef, name: devices.find(device => device.ref === deviceRef)}.name)))
                setDevices(devices)
	        })
        }
        if (sensorIcons.length == 0) {
            sensorIconService.getAll().then(sensorIcons => {
                setSensorIcons(sensorIcons)
            })
        }
    }, [])

    function addParam(e) {
        e.preventDefault()

        let newParams = {...state.params, '': ''}

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function removeParam(e, key) {
        e.preventDefault();

		let newParams = {...state.params}
        delete newParams[key]

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleKeyChange(e, key) {
        let targetValue = e.target.value

        var newParams = {}
        for (const [prevKey, prevValue] of Object.entries(state.params)) {
            console.log(prevKey + ': ' + prevValue)
            if (prevKey !== key) {
                newParams[prevKey] = prevValue
            } else {
                while (targetValue in state.params) {
                    targetValue = '_' + targetValue
                }
                newParams['' + targetValue] = prevValue
            }
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...state.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function onChange(e) {
        setSelectedDevices(e)
        handleChange({target: {type: "select", name: "deviceRefs", value: e.map(device => (device.ref))}})
    }

    function onImageChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
                handleChange({target: {type: "bool", name: "imageExists", value: true}})
                handleChange({target: {type: "file", name: "image", value: hexString}})
                setNewImage(hexString)
            });
            reader.readAsBinaryString(file)
        }
    }

    const containerStyle = {
		width: '100%',
		height: '600px'
	};

	function handleCenter() {
        if (map === null)
            return
        const newPos = map.getCenter().toJSON();
    }

	const onLoad = React.useCallback(function callback(map) {
	    //const bounds = new window.google.maps.LatLngBounds();
	    //map.fitBounds(bounds);
	    //setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onMarkerLoad = marker => {
        console.log(marker)
    }

    function onClick(e) {
        var lat = parseFloat(e.latLng.lat().toFixed(6))
        var lng = parseFloat(e.latLng.lng().toFixed(6))
        handleChange({target: {type: "number", name: "lat", value: lat}})
        handleChange({target: {type: "number", name: "lng", value: lng}})
    }

    function copyId() {
        const textArea = document.createElement("textarea");
        textArea.innerText = state.id;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
    }

    function deleteImage(e) {
        handleChange({target: {type: "bool", name: "imageExists", value: false}})
        handleChange({target: {type: "file", name: "image", value: null}})
        setNewImage(null)
    }

    function handleLatLngChange(e) {
        console.log('handleLatLngChange')
        console.log(Number(e.target.value))
        console.log(Number('A'))
        if (Number(e.target.value)) {
            handleChange({target: {type: "number", name: e.target.name, value: parseFloat(e.target.value)}})
        }
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Row>
	            <Col sm={5}>
		            <Form.Group controlId="accessTag">
		                <Form.Label>
		                    {t('sensor.columns.accesstag')}
		                </Form.Label>
		                <Form.Control as="select" name="accessTag" value={state.accessTag} onChange={handleChange} required disabled={state.id || state.parent}>
		                    { accessTags.map(accessTag => {
		                        return <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
		                    })}
		                </Form.Control>
		            </Form.Group>
		            { state.id &&
			            <Form.Group controlId="id">
			                <Form.Label>
			                    {t('sensor.columns.id')}
			                </Form.Label>
			                <InputGroup className="mb-3">
			                    <Form.Control type="text" name="id" value={state.id} disabled/>
			                    <InputGroup.Append>
									<Button variant="outline-secondary" onClick={copyId}>
										<FontAwesomeIcon icon={faClipboard}/>
									</Button>
								</InputGroup.Append>
			                </InputGroup>
			            </Form.Group>
		            }
		            <Form.Group controlId="name">
		                <Form.Label>
		                    {t('sensor.columns.name')}
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('sensor.columns.name')} name="name" value={state.name} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="description">
		                <Form.Label>
		                    {t('sensor.columns.description')}
		                </Form.Label>
		                <Form.Control as="textarea" rows={5} placeholder={t('sensor.columns.description')} name="description" value={state.description || ''} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="parent">
		                <Form.Label>
		                    {t('sensor.columns.parent')}
		                </Form.Label>
		                <Form.Control as="select" name="parent" value={state.parent} onChange={handleChange}>
		                    <option key="null" value="null">{t('sensor.noparent')}</option>
		                    { nodes.map(node => {
		                        return <option key={node.id} value={node.id}>{node.name}</option>
		                    })}
		                </Form.Control>
		            </Form.Group>
		            <Form.Group controlId="type">
		                <Form.Label>
		                    {t('sensor.columns.type')}
		                 </Form.Label>
		                <Form.Control as="select" name="type" value={state.type} onChange={handleChange}>
		                    { sensorTypes.map(sensorType => {
		                        return <option key={sensorType} value={sensorType}>{sensorType}</option>
		                    })}
		                </Form.Control>
		            </Form.Group>
		            <Form.Group controlId="deviceRefs">
		                <Form.Label>
		                    {t('sensor.columns.devices')}
		                </Form.Label>
		                <Select placeholder={t('sensor.columns.devices')} isLoading={devices.length === 0} options={devices} value={selectedDevices} getOptionLabel={device => device.name} getOptionValue={device => device.ref} isMulti={true} closeMenuOnSelect={false} onChange={onChange}/>
		            </Form.Group>
		            <Form.Group controlId="icon">
                        <Form.Label>
                            {t('sensor.columns.icon')}
                        </Form.Label>
                        <Form.Check id={-1} key={-1} type="radio" label={t('sensor.noicon')} name="icon" value="" checked={state.icon == '' || state.icon == null} onChange={handleChange}/>
                        {sensorIcons.map(sensorIcon => (
                            <Form.Check id={sensorIcon.id} key={sensorIcon.id} type="radio" label={<span><img src={"data:image/png;base64, " + sensorIcon.data} style={{width: '25px'}}/> {sensorIcon.name}</span>} name="icon" value={sensorIcon.id} checked={sensorIcon.id == state.icon} onChange={handleChange}/>
                        ))}
                    </Form.Group>
                    <Form.Group controlId="public_">
                        <Form.Check type="checkbox" label={t('sensor.columns.public')} name="public_" checked={state.public_} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="paused">
                        <Form.Check type="checkbox" label={t('sensor.columns.paused')} name="paused" checked={state.paused} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="category">
                        <Form.Label>
                            {t('sensor.columns.category')}
                        </Form.Label>
		                <Form.Control as="select" name="category" value={state.category} onChange={handleChange}>
		                    <option key="" value="">{t('sensor.nocategory')}</option>
		                    { categories.map(category => {
		                        return <option key={category.id} value={category.id}>{category.name}</option>
		                    })}
		                </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="image">
		                <Form.Label>
		                    {t('sensor.columns.image')}
		                </Form.Label>
		                { newImage &&
		                    <>
			                    <div className="text-center">
			                        <img src={"data:image/png;base64," + newImage} style={{ maxWidth: '100%' }}/>
			                    </div>
			                    <Button variant="outline-secondary" variant="outline-danger" onClick={deleteImage}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
							</>
		                }
		                { !newImage && state.imageExists &&
		                    <>
			                    <div className="text-center">
			                        <img src={SERVER_URL + "/sensor/getImage/" + state.id} style={{ maxWidth: '100%' }}/>
			                    </div>
			                    <Button variant="outline-secondary" variant="outline-danger" onClick={deleteImage}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
							</>
		                }
		                <Form.Control type="file" accept=".jpg" name="image" onChange={onImageChange} />
		                <Form.Control.Feedback type="invalid">
		                    {t('sensor.errors.imageinvalid')}
		                </Form.Control.Feedback>
		            </Form.Group>
		            <Form.Group controlId="params">
		                <Form.Label>
		                    {t('sensor.columns.params')}
		                </Form.Label>
		                <br/>
		                <table style={{width: '100%'}}>
		                    { Object.entries(state.params).length > 0 &&
		                        <thead>
			                        <tr>
			                            <th style={{width: '33%'}}>
			                                {t('sensor.params.key')}
			                            </th>
			                            <th style={{width: '67%'}}>
			                                {t('sensor.params.value')}
			                            </th>
			                            <th></th>
			                        </tr>
		                        </thead>
		                    }
		                    <tbody>
				                {Object.entries(state.params).map(([key, value], index) => {
									return <tr key={index}>
										<td>
											<Form.Control required type="text" placeholder={t('node.params.key')} name="key" value={key} onChange={(e) => handleKeyChange(e, key)} />
										</td>
										<td>
											<Form.Control required type="text" placeholder={t('node.params.value')} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
										</td>
			                            <td>
											<Button
					                            onClick={(e) => removeParam(e, key)}
					                            title="Delete"
					                            size="sm"
					                            color="info"
					                            variant="outline-danger">
					                            <FontAwesomeIcon icon={faTrash}/>
					                        </Button>
				                        </td>
									</tr>
				                })}
			                </tbody>
		                </table>
		                <Button
		                    onClick={addParam}
		                    title={t('sensor.params.addparam')}
		                    color="info"
		                    variant="outline-primary">
		                    <FontAwesomeIcon icon={faPlus}/> {t('sensor.params.addparam')}
		                </Button>
		            </Form.Group>
                </Col>
	            <Col sm={7}>
	                <Form.Group controlId="marker">
                        <Form.Check type="checkbox" label={t('sensor.columns.marker')} name="marker" checked={state.marker} onChange={handleChange}/>
                    </Form.Group>
                    { state.marker &&
                        <>
			                <Form.Group controlId="lat">
				                <Form.Label>
				                    {t('sensor.columns.lat')}
				                </Form.Label>
				                <Form.Control required type="number" placeholder={t('sensor.columns.lat')} name="lat" value={state.lat} onChange={handleLatLngChange} step="0.1"/>
				            </Form.Group>
				            <Form.Group controlId="lng">
				                <Form.Label>
				                    {t('sensor.columns.lng')}
				                </Form.Label>
				                <Form.Control required type="number" placeholder={t('sensor.columns.lng')} name="lng" value={state.lng} onChange={handleLatLngChange} step="0.1"/>
				            </Form.Group>
				            <Form.Group controlId="updatable">
		                        <Form.Check type="checkbox" label={t('sensor.columns.updatable')} name="updatable" checked={state.updatable} onChange={handleChange}/>
		                    </Form.Group>
							<GoogleMap
								mapContainerStyle={containerStyle}
								center={{lat: parseFloat(state.lat), lng: parseFloat(state.lng)}}
								zoom={10}
								onLoad={onLoad}
								onUnmount={onUnmount}
								onDragEnd={handleCenter}
								onClick={onClick}>
								{ /* Child components, such as markers, info windows, etc. */
								    <Marker
								      key={1}
								      onLoad={onMarkerLoad}
								      position={{lat: parseFloat(state.lat), lng: parseFloat(state.lng)}}
								      draggable={true}
								      onDragEnd={onClick}
								    />
								}
					        </GoogleMap>
					    </>
                    }
	            </Col>
		    </Row>
        </Form>
    )
}

export default SensorForm