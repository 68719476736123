import React, { useState, useEffect } from 'react';
import CrudTable from '../table/crudtable'
import { sensorIconService, accessTagService, authService } from '../../_services'
import SensorIconForm from './sensoriconform.component'
import { useTranslation } from "react-i18next";
import { SERVER_URL } from '../../config';

function SensorIcon() {

	const [accessTags, setAccessTags] = useState([])
	const {t} = useTranslation('common');

	const initialState = {
		accessTag: '',
		name: '',
		data: '',
		size: 32
	}

	useEffect(() => {
		if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
            })
		}
    }, [])

	const dataFormatter = function(cell) {
        return <img src={"data:image/png;base64, " + cell} style={{maxWidth: '25px', maxHeight: '25px'}}/>
    }

    const accessTagFormatter = function(cell) {
        var accessTag = accessTags.find((accessTag) => accessTag.id == cell)
        if (accessTag) {
			return accessTag.name
        }
        return cell
    }

	const columns = [
		{
            dataField: 'data',
            text: '',
            formatter: dataFormatter,
            headerStyle: { width: 40 }
        },
		{
			dataField: 'name',
			text: t('sensoricon.columns.name'),
			sort: true
		},
		{
			dataField: 'size',
			text: t('sensoricon.columns.size'),
			sort: true
		},
		{
			dataField: 'accessTag',
			text: t('sensoricon.columns.accesstag'),
			formatter: accessTagFormatter,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('sensoricon.label')}
			</h2>
			{ accessTags.length > 0 &&
				<CrudTable
					service={sensorIconService}
					columns={columns}
					initialState={initialState}
					form={<SensorIconForm />}
					sort={{dataField: 'name', order: 'asc'}}
					creatable={authService.hasRoles(["ROLE_SENSOR_ICON_WRITE"])}
					editable={authService.hasRoles(["ROLE_SENSOR_ICON_WRITE"])}
					deletable={authService.hasRoles(["ROLE_SENSOR_ICON_WRITE"])}
				/>
			}
		</div>
	)
}

export default SensorIcon;