import React from 'react';
import CrudTable from '../table/crudtable'
import { changeLogService } from '../../_services'
import ChangeLogForm from './changelogform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function ChangeLog() {

  const {t} = useTranslation('common');

  const initialState = {
      versionCode: '',
      description: '',
  }

  const dateFormatter = function(cell) {
    return moment(cell).format('YYYY-MM-DD HH:mm:ss')
  }

  const columns = [
    {
      dataField: 'versionCode',
      text: t('changelog.columns.versioncode'),
      sort: true
    },
    {
      dataField: 'dateCreated',
      text: t('changelog.columns.datecreated'),
      formatter: dateFormatter,
      sort: true
    },
    {
      dataField: 'description',
      text: t('changelog.columns.description'),
      sort: true
    }
  ]

  return (
    <div>
      <h2>
        {t('changelog.label')}
      </h2>
      <CrudTable 
        service={changeLogService}
        columns={columns}
        initialState={initialState}
        form={<ChangeLogForm />}
        sort={{dataField: 'versionCode', order: 'desc'}}
      />
      </div>
  )
}

export default ChangeLog;