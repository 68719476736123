import api from './api.service'

export const deviceService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/devices'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(customer) {
    return api.post(BASE_URL, customer)
}

function update(id, customer) {
    return api.put(BASE_URL + "/" + id, customer)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
