import React, { useEffect, useState } from 'react';
import { resultService } from '../../_services'
import Table from 'react-bootstrap/Table'
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

function DataResult({interval, resolution, sensor, dataKey, postfix = "", header = "", description = "", footer = ""}) {

    const [data, setData] = useState([])
    const [lastUpdate, setLastUpdate] = useState(null)

    const icon = <FontAwesomeIcon icon={faThermometerFull} />
 
    useEffect(() => {
        updateData()
    }, [interval, resolution, sensor]);

    function updateData() { 
        resultService.openRead({
            interval: interval,
            resolution: resolution,
            sensor: sensor
        }).then(data => {
            const keys = Object.keys(data)
            if (keys.length > 0) {
                if (data[dataKey]) {
                    setData(data[dataKey][Object.keys(data[dataKey])].reverse())
                }
                setLastUpdate(data.lastUpdate)
            }
        })
    }

    const listItems = data.slice(1).map((d) =>
        <tr key={d.x}>
            <td align={'center'}>
                {icon} {d.y}{postfix} {moment.unix(d.x/1000).format("HH:mm")}
            </td>
        </tr>
    );

    return (
        <Container fluid="sm">
            <Row>
                <Col>
                    <div>{header}</div>
                    <div>{description}</div>
                    {data.length > 0 && "Senaste värde: " + data[0].y + postfix}
                    <br />
                    {lastUpdate && "Togs emot: " + moment.unix(lastUpdate / 1000).format("YYYY-MM-DD HH:mm:ss")}
                    <Table striped bordered hover size="sm">
                        <tbody>
                                {listItems}
                        </tbody>
                    </Table>
                    <div>{footer}</div>
                </Col>
            </Row>
        </Container>
    )
}

export default DataResult;