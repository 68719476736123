import React from 'react';
import CrudTable from '../table/crudtable'
import { appLogService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function AppLogList() {

  const {t} = useTranslation('common');

  const dateFormatter = function(cell) {
    return moment(cell).format('YYYY-MM-DD HH:mm:ss')
  }

  const positionFormatter = function(cell, row) {
    if (row.lat == 1000 || row.lng == 1000) {
        return 'N/A'
    }
    return <a href={"https://www.google.se/maps/search/?api=1&query=" + row.lat + "," + row.lng} target="_blank">{row.lat}, {row.lng}</a>
  }

  const typeFormatter = function(cell, row) {
    if (cell === 'EMBED' && row.website) {
        return cell + ' (' + row.website + ')'
    }
    return cell
  }

  const columns = [
    {
      dataField: 'dateCreated',
      text: t('apploglist.columns.datecreated'),
      formatter: dateFormatter,
      sort: true
    },
    {
      dataField: 'type',
      text: t('apploglist.columns.type'),
      formatter: typeFormatter,
      sort: true
    },
    {
      dataField: 'remoteAddress',
      text: t('apploglist.columns.remoteaddress'),
      sort: true
    },
    {
      dataField: 'position',
      text: t('apploglist.columns.position'),
      formatter: positionFormatter
    }
  ]

  return (
    <div>
      <h2>
        {t('apploglist.label')}
      </h2>
      <CrudTable 
        service={appLogService}
        columns={columns}
        creatable={false}
		editable={false}
		deletable={true}
        sort={{dataField: 'dateCreated', order: 'desc'}}
      />
      </div>
  )
}

export default AppLogList;