import api from './api.service'

export const environmentService = {
    get,
    saveEnvironment,
    loadEnvironment,
    getLogo
};

const BASE_URL = '/environment'

function get() {
    return api.get(BASE_URL)
}

function saveEnvironment(environment) {
	localStorage.setItem('environment', environment)
}

function loadEnvironment() {
	return localStorage.getItem('environment')
}

function getLogo() {
	const environment = loadEnvironment()
	switch (environment) {
	    case 'development':
	        return "/logos/iqmtel.png"
		case 'production':
	        return "/logos/iqmtel.png"
	    case 'production_wexnet':
	        return "/logos/wexnet.png"
	}
	return "/logos/iqmtel.png"
}
