import React, {useState} from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { useTranslation } from "react-i18next";

function AddGroupButton(props) {

    const [show, setShow] = useState(false)
    const {t} = useTranslation('common');

    function handleConfirm (data) {
        setShow(false)
        props.confirmClick(data)
    }

    return (
        <>
            <Button
                onClick={() => setShow(true)}
                title={t("crudtable.add")}
                size="sm"
                color="info"
                variant="outline-secondary">
                {t("node.add")} <FontAwesomeIcon icon={faPlus}/>
            </Button>
	        <ModalForm
	            data={props.data}
	            show={show}
	            handleConfirm={handleConfirm}
	            handleClose={() => setShow(false)}
	            modalHeader={t("crudtable.add")}
	            modalBody={props.form}
	            size={props.size}
	        />
        </>
    )

}

export default AddGroupButton