import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {authService} from '../_services';

function ProtectedRoute({ component: Component, permission=[], ...rest }) {
    return (
        <Route {...rest} render={props => {
            if (!authService.isAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            } else if (permission && permission.length > 0 && !authService.hasAnyRole(permission)) {
                return <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { ProtectedRoute };