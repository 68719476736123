import React from 'react';
import CrudTable from '../table/crudtable'
import { domainChangeLogService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function DomainChangeLog() {

    const {t} = useTranslation('common');

	const initialState = {
		customer: -1,
		username: '',
		password: '',
		enabled: true,
		accountExpired: false,
		accountLocked: false,
		passwordExpired: false,
		userRoles: []
	}

	const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const columns = [
		{
		    dataField: 'dateCreated',
		    text: t('domainchangelog.columns.datecreated'),
            formatter: formatDate,
			sort: true
		},
		{
			dataField: 'user.username',
			text: t('domainchangelog.columns.username'),
			sort: true
		},
        {
            dataField: 'type',
            text: t('domainchangelog.columns.type'),
            sort: true
        },
        {
            dataField: 'objectClass',
            text: t('domainchangelog.columns.class'),
            sort: true
        },
		{
			dataField: 'objectId',
			text: t('domainchangelog.columns.id'),
			sort: true
		}
	]

	return (
		<div>
		  <h2>
		    {t('domainchangelog.label')}
		  </h2>
		  <CrudTable
		    service={domainChangeLogService}
		    columns={columns}
		    initialState={initialState}
		    remote={{"filter": false, "search": false, "pagination": false}}
		    creatable={false}
		    editable={false}
		    deletable={true}
		    sort={{dataField: 'dateCreated', order: 'desc'}}
		  />
		</div>
	)
}

export default DomainChangeLog;