import React from 'react';
import CrudTable from '../table/crudtable'
import { deviceModelService } from '../../_services'
import DeviceModelForm from './devicemodelform.component'
import { useTranslation } from "react-i18next";

function DeviceModel() {

  const {t} = useTranslation('common');

  const initialState = {
      id: -1,
      type: "DeviceModel",
      dateCreated: '',
      dateModified: '',
      category: [],
      controlledProperty: [],
      manufacturerName: "",
      brandName: "",
      modelName: ""
  }

  const columns = [
    {
      dataField: 'modelName',
      text: t('devicemodel.columns.modelname'),
      sort: true
    },
    {
      dataField: 'brandName',
      text: t('devicemodel.columns.brandname'),
      sort: true
    },
    {
        dataField: 'manufacturerName',
        text: t('devicemodel.columns.manufacturername'),
        sort: true
    }
  ]

  return (
    <div>
      <h2>
        {t('devicemodel.label')}
      </h2>
      <CrudTable
        service={deviceModelService}
        columns={columns}
        initialState={initialState}
        form={<DeviceModelForm />}
        remote={{"filter": false, "search": false, "pagination": false}}
        sort={{dataField: 'brandName', order: 'asc'}}
      />
      </div>
  )
}

export default DeviceModel;