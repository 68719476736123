import api from './api.service'

export const sensorTypeService = {
    getAll
};

const BASE_URL = '/sensorTypes/'

function getAll() {
    return api.get(BASE_URL)
}
