import React, { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { userService, authService } from '../../_services'
import { history } from '../../_helpers';

function SwitchUser() {

	const [users, setUsers] = useState([]);
	const [userId, setUserId] = useState(null);
    const {t} = useTranslation('common');

    useEffect(() => {
		userService.getAll().then(data => {
			data = data.filter(user => user.id != 1)
			data.sort((a, b) => a.username.localeCompare(b.username))
            setUsers(data)
            setUserId(data[0].id)
        })
    }, [])

    const onClick = () => {
        if (userId) {
			authService.switchUser(userId).then(response => {
				history.push("/")
			})
        }
    }

    const handleChange = (event) => {
        setUserId(event.target.value)
    }

	return (
		<div>
			<h2>
                {t('switchuser.label')}
            </h2>
			<Col md={{ span: 3, offset: 0 }}>
				<Form.Control required as="select" name="user" onChange={handleChange}>
	                {users.map(user => (
	                    <option key={user.id} value={user.id}>{user.username} ({user.customerName})</option>
	                ))}
	            </Form.Control>
            </Col>
			<Col md={{ span: 3, offset: 0 }}>
				<Button onClick={onClick}>{t('switchuser.switchuser')}</Button>
			</Col>
        </div>
    )
}

export default SwitchUser;