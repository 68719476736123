import api from './api.service'

export const sensorService = {
    getAll,
    getAllMarkers,
    save,
    update,
    _delete
};

const BASE_URL = '/sensors/'

function getAll() {
    return api.get(BASE_URL, {sort: 'name', order: 'asc'})
}

function getAllMarkers() {
    return api.get('/sensor/' + 'getAllMarkers')
}

function save(sensor) {
    return api.post(BASE_URL, sensor)
}

function update(id, sensor) {
    return api.put(BASE_URL + id, sensor)
}

function _delete(id) {
    return api.delete(BASE_URL + id)
}
