import api from './api.service'

export const networkService = {
    getAll
};

const BASE_URL = '/networks/'

function getAll(networkType) {
    return api.get(BASE_URL + '?type=' + networkType)
}
