import React, { useState, useEffect } from 'react'
import {Form} from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider';
import { useTranslation } from "react-i18next";
import { accessTagService } from '../../_services'

const CategoryForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common')

    useEffect(() => {
		if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (state.accessTag == '') {
                    handleChange({target: {name: "accessTag", value: accessTags[0].id}})
                }
            })
		}
    }, [props])

    function onChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
                handleChange({target: {type: "file", name: "data", value: hexString}})
            });
            reader.readAsBinaryString(file)
        }
    }

    function onSlide(changeEvent) {
        handleChange({target: {name: "size", value: changeEvent.target.value}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="accessTag">
                <Form.Label>
                    {t('category.columns.accesstag')}
                </Form.Label>
                <Form.Control as="select" name="accessTag" value={state.accessTag} onChange={handleChange} required>
                    { accessTags.map(accessTag => {
                        return <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('category.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('category.columns.name')} name="name" value={state.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="description">
                <Form.Label>
                    {t('category.columns.description')}
                </Form.Label>
                <Form.Control type="text" placeholder={t('category.columns.description')} name="description" value={state.description || ''} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="position">
                <Form.Label>
                    {t('category.columns.position')}
                </Form.Label>
                <Form.Control required type="number" min="0" placeholder={t('category.columns.position')} name="position" value={state.position} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="public_">
	            <Form.Check type="checkbox" name="public_" label={t('category.columns.public')} checked={state.public_} onChange={handleChange} />
	        </Form.Group>
        </Form>
    )
}

export default CategoryForm