import api from './api.service'

export const adminAccessTagService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/admin/accessTags/'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100, sort: 'name', order: 'asc'})
}

function save(accessTag) {
    return api.post(BASE_URL, accessTag)
}

function update(id, accessTag) {
    return api.put(BASE_URL + id, accessTag)
}

function _delete(id) {
    return api.delete(BASE_URL + id)
}
