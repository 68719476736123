import React, { useEffect, useState, useRef } from 'react';
import { resultService, nodeService, sensorService } from '../../_services'
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes';
import { Form, Button, ButtonGroup, Row, Col } from 'react-bootstrap'
import './bootstrap-dropdown-select.css'
import 'font-awesome/css/font-awesome.min.css'
import DropdownContainer from '../dropdowncontainer.component'
import * as moment from 'moment'

function DataExplorer() {

    let _sensorMap = useRef();
    let _nodeMap = useRef();

    const [data, setData] = useState([])
    const [chartData, setChartData] = useState()
    const [interval, setInterval] = useState('-24h')
    const [resolution, setResolution] = useState('hour')
    const [valueCalculation, setValueCalculation] = useState('MEAN')
    const [columns, setColumns] = useState([])
    const [selectedColumn, setSelectedColumn] = useState("")
    const [nodes, setNodes] = useState([])
    const [selectedSensors, setSelectedSensors] = useState([])

    const options = {
        scales: {
            xAxes: [{
                type: 'time',
                time: {    
                    isoWeekday: true,                                                                                                                                                          
                    tooltipFormat: 'YYYY-MM-DD HH:mm',
                    displayFormats: {
                        millisecond: 'HH:mm:ss.SSS',
                        second: 'HH:mm:ss',
                        minute: 'HH:mm',
                        hour: 'HH:mm',
                    }
                },
                ticks: {
                    major: {
                        enabled: true
                    }
                }
            }],
        },
        plugins: {
            colorschemes: {
                scheme: 'brewer.Paired12'
            }
        },
        tooltips: {
            callbacks: {
                title: function (toolTipItems, data) {
					var d = moment(toolTipItems[0].label, 'YYYY-MM-DD HH:mm')
                    switch (resolution) {
                        case 'minute':
                            return toolTipItems[0].label
                        case 'hour':
                            return toolTipItems[0].label
                        case 'day':
                            return d.format('YYYY-MM-DD')
                        case 'week':
                            return 'Week ' + d.isoWeek() + ' (' + d.format('YYYY-MM-DD') + ' - ' +
                                d.add(6, 'days').format('YYYY-MM-DD') + ')'
                        case 'month':
                            return d.format('YYYY-MM')
                    }
                    return toolTipItems[0].label
                }
            }
        }
    }

    const chart = {
        datasets: chartData
    };

    useEffect(() => {
        if (nodes.length === 0) {
            Promise.all([sensorService.getAll(), nodeService.getAll()]).then((values) => {
                const [sensors, _nodes] = values

                _sensorMap.current = sensors.reduce(function(map, obj) {
                    map[obj.id] = obj;
                    return map;
                }, {});

                _nodeMap.current = _nodes.reduce(function(map, obj) {
                    map[obj.id] = obj;
                    return map;
                }, {});
                const topNodes = _nodes.filter(node => node.parent === null)
                
                setNodes(topNodes.map( topNode => buildNodeTree(topNode.id, _nodeMap.current, _sensorMap.current)))
            });
        }
        if (selectedSensors.length > 0 ) {
            updateData(selectedSensors)
        }
    }, [interval, resolution, valueCalculation, selectedSensors]);

    const onChange = (currentNode, selectedNodes) => {
        var sensorIds = []

        for (var selectedNode of selectedNodes) {
            if (selectedNode.type === 'sensor') {
                sensorIds.push(selectedNode.value) // Add id to list
            } else {
                handleNode(_nodeMap.current[selectedNode.value], _nodeMap.current, sensorIds) // Traverse tree
            }
        }

        setSelectedSensors(sensorIds)
    }

    function handleNode(node, nodeMap, sensorIds) {
        for (var sensorId of nodeMap[node.id].sensors) {
            sensorIds.push(sensorId)
        }
        for (var childNode of nodeMap[node.id].children) {
            handleNode(nodeMap[childNode], nodeMap, sensorIds)
        }
    }
       
    function buildNodeTree(nodeId, nodeMap, sensorMap) {
        var node = nodeMap[nodeId]
        var childList = []
        if (node.children.length > 0) {
            childList = node.children.map(childId => buildNodeTree(childId, nodeMap, sensorMap))
            childList.sort((a, b) => a.label.localeCompare(b.label))
        }

		var sensorList = []
        for (var sensorId of node.sensors) {
            var sensor = sensorMap[sensorId]
            sensorList.push({
                label: sensor.name,
                value: sensor.id,
                type: 'sensor',
                checked: false
            })
        }
        if (sensorList.length > 0) {
            sensorList.sort((a, b) => a.label.localeCompare(b.label))
            childList.push(...sensorList)
        }

        var allChildrenDisabled = childList.every(child => child.disabled)

        return {
            label: node.name,
            value: node.id,
            type: 'node',
            children: childList,
            checked: false,
            disabled: allChildrenDisabled || childList.length == 0
        }
    }

    function updateData(sensorIds) { 
        resultService.readSensors({
            interval: interval,
            resolution: resolution,
            sensors: sensorIds,
            valueCalculation: valueCalculation
        }).then(data => {
            const measurementTypes = Object.keys(data)
            setColumns(measurementTypes)

            if (!measurementTypes.includes(selectedColumn)) { 
                if (measurementTypes.length > 0) {
                    setDataForMeasurementType(measurementTypes[0], data)
                }
            } else {
                setDataForMeasurementType(selectedColumn, data)
            }
            setData(data)
        })
    }

    const columnUpdate = (e) => {
        const target = e.target
        const measurementType = target.value;
        setDataForMeasurementType(measurementType, data)
    }

    function setDataForMeasurementType(measurementType, data) {
        const datasets = []
        
        for (const column of Object.keys(data[measurementType])) {
            datasets.push({
                label: _sensorMap.current[column].name,
                fill: false,
                lineTension: 0.1,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: data[measurementType][column]
            })
        }

        setChartData(datasets)
        setSelectedColumn(measurementType)
    }

    return (
        <>
            <h2>
                Data Explorer
            </h2>
            <DropdownContainer data={nodes} 
                onChange={onChange} 
                className="bootstrap-treeselect"
            />
            <Row>
                <Col>
		            <ButtonGroup aria-label="interval">
		                <Button variant="secondary" onClick={() => setInterval('-4h')} active={interval === '-4h'}>4h</Button>
		                <Button variant="secondary" onClick={() => setInterval('-24h')} active={interval === '-24h'}>24h</Button>
		                <Button variant="secondary" onClick={() => setInterval('-1w')} active={interval === '-1w'}>1 Week</Button>
		                <Button variant="secondary" onClick={() => setInterval('-1mo')} active={interval === '-1mo'}>1 Month</Button>
		                <Button variant="secondary" onClick={() => setInterval('0')} active={interval === '0'}>Everything</Button>
		            </ButtonGroup>
	            </Col>
	            <Col style={{textAlign: 'center'}}>
		            <ButtonGroup aria-label="function">
		                <Button variant="secondary" onClick={() => setValueCalculation('MEAN')} active={valueCalculation === 'MEAN'}>Mean</Button>
		                <Button variant="secondary" onClick={() => setValueCalculation('SUM')} active={valueCalculation === 'SUM'}>Sum</Button>
		                <Button variant="secondary" onClick={() => setValueCalculation('MIN')} active={valueCalculation === 'MIN'}>Min</Button>
		                <Button variant="secondary" onClick={() => setValueCalculation('MAX')} active={valueCalculation === 'MAX'}>Max</Button>
		            </ButtonGroup>
	            </Col>
	            <Col>
		            <ButtonGroup aria-label="resolution" className="float-right">
		                <Button variant="secondary" onClick={() => setResolution('minute')} active={resolution === 'minute'}>Minute</Button>
		                <Button variant="secondary" onClick={() => setResolution('hour')} active={resolution === 'hour'}>Hour</Button>
		                <Button variant="secondary" onClick={() => setResolution('day')} active={resolution === 'day'}>Daily</Button>
		                <Button variant="secondary" onClick={() => setResolution('week')} active={resolution === 'week'}>Weekly</Button>
		                <Button variant="secondary" onClick={() => setResolution('month')} active={resolution === 'month'}>Monthly</Button>
		            </ButtonGroup>
	            </Col>
            </Row>
            <Form.Group controlId="column">
                <Form.Label>
                    Column:
                </Form.Label>
                <Form.Control disabled={columns.length === 0} required as="select" name="column" onChange={columnUpdate}>
                    {columns.map(column => (
                        <option key={column} value={column}>{column.substring(0, 1).toUpperCase() + column.substring(1)}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Line 
                data={chart} 
                options={options} 
            />
        </>
    )
}

export default DataExplorer;