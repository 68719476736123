import api from './api.service'

export const customerAccessTagService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/customerAccessTags'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(customerAccessTag) {
    return api.post(BASE_URL, customerAccessTag)
}

function update(id, customerAccessTag) {
    return api.put(BASE_URL + "/" + id, customerAccessTag)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
