 
 
 export const fiware = {
    categories: [
        "actuator",
        "beacon",
        "endgun",
        "HVAC",
        "implement",
        "irrSystem",
        "irrSection",
        "meter",
        "network",
        "multimedia",
        "sensor"
      ],
      controlledProperties: [
        "temperature",
        "humidity",
        "light",
        "motion",
        "fillingLevel",
        "occupancy",
        "power",
        "pressure",
        "smoke",
        "energy",
        "airPollution",
        "noiseLevel",
        "weatherConditions",
        "precipitation",
        "windSpeed",
        "windDirection",
        "atmosphericPressure",
        "solarRadiation",
        "depth",
        "pH",
        "conductivity",
        "conductance",
        "tss",
        "tds",
        "turbidity",
        "salinity",
        "orp",
        "cdom",
        "waterPollution",
        "location",
        "speed",
        "heading",
        "weight",
        "waterConsumption",
        "gasComsumption",
        "electricityConsumption",
        "eatingActivity",
        "milking",
        "movementActivity",
        "soilMoisture"
      ]
 }