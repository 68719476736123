import {SERVER_URL} from '../config';
import axios from "axios";
import { history, authHeader } from '../_helpers';

export const authService = {
    login,
    logout,
    isAuthenticated,
    getCurrentUser,
    getToken,
    hasRoles,
    hasAnyRole,
    switchUser,
    switchBack,
    hasProducts
};

function login(username, password) {
    return axios
      .post(SERVER_URL + "/login", {
        username: username,
        password: password
      })
      .then(response => {
        console.log(response)
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
}

function hasRoles(roles) {
  const userRoles = new Set(getCurrentUser()?.roles || [])
  return roles.every(role => userRoles.has(role))
}

function hasProducts(products) {
  const userProducts = new Set(getCurrentUser()?.products || [])
  return products.every(product => userProducts.has(product))
}

function hasAnyRole(roles) {
  const userRoles = new Set(getCurrentUser()?.roles || [])
  return roles.some(role => userRoles.has(role))
}

function isAuthenticated() {
  try {
    return !(localStorage.getItem("user") === null)
  }
  catch(err) {
    return false
  }
    
}

function getCurrentUser() {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch(err) {
    return null
  }
}

function getToken() {
  return this.getCurrentUser().access_token
}

function logout(from) {
    // remove user from session storage to log user out
    localStorage.removeItem('user');
    const relay = from || history.location
    history.push("/login", { from: relay })
}

function switchUser(user) {
	var headers = {
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    }
    return axios
        .get(SERVER_URL + "/switch/index/" + user, { headers: headers })
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

function switchBack() {
	var headers = {
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    }
    return axios
        .get(SERVER_URL + "/switch/switchBack/", { headers: headers })
        .then(
            response => {
	            if (response.data.access_token) {
	                localStorage.setItem("user", JSON.stringify(response.data));
	            }
	            return response.data;
	        }
        );
}
