import React, {useState, useEffect, useRef} from 'react'
import { Form, Button, Card, InputGroup, CloseButton, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { sensorService, resultService, deviceService, deviceModelService, nodeService, actionConfigurationService } from '../../_services'
import DropdownContainer from '../dropdowncontainer.component'

const ActionConfigurationForm = (props) => {

	let _sensorMap = useRef();
	let _nodeMap = useRef();

    const state = props.data
    const [resultTypes, setResultTypes] = useState([])
    const [devices, setDevices] = useState([])
    const [deviceModels, setDeviceModels] = useState([])
    const [nodes, setNodes] = useState([])
    const [selectedSensors, setSelectedSensors] = useState([])
    const [actionConfigurations, setActionConfigurations] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        if (nodes.length === 0) {
            Promise.all([sensorService.getAll(), nodeService.getAll()]).then((values) => {
                const [sensors, _nodes] = values

                if (state.id) {
                    var actionConfigurationSensors = sensors.map(sensor => {
                        var existingActionConfigurationSensor = state.actionConfigurationSensors.find(actionConfigurationSensor => {
                            return actionConfigurationSensor.sensor == sensor.id
                        })
                        if (existingActionConfigurationSensor) {
                            return {id: existingActionConfigurationSensor.id, sensor: sensor.id, sensorName: sensor.name}
                        }
                        return {sensor: sensor.id, sensorName: sensor.name}
                    })
                    actionConfigurationSensors.sort((a, b) => a.sensorName.localeCompare(b.sensorName))
                } else {
                    var actionConfigurationSensors = sensors.map(sensor => ({sensor: sensor.id, sensorName: sensor.name}))
                    actionConfigurationSensors.sort((a, b) => a.sensorName.localeCompare(b.sensorName))
                }

                _sensorMap.current = actionConfigurationSensors.reduce(function(map, obj) {
                    map[obj.sensor] = obj;
                    return map;
                }, {});

                _nodeMap.current = _nodes.reduce(function(map, obj) {
                    map[obj.id] = obj;
                    return map;
                }, {});
                const topNodes = _nodes.filter(node => node.parent === null)

                setNodes(topNodes.map( topNode => buildNodeTree(topNode.id, _nodeMap.current, _sensorMap.current)))
            });
        }
        if (resultTypes.length === 0) {
            resultService.getResultTypes().then(resultTypes => {
                setResultTypes(resultTypes)
            })
        }
        if (devices.length == 0) {
            deviceService.getAll().then(devices => {
                devices.sort((a, b) => {return a.name.localeCompare(b.name)})
                setDevices(devices)
	        })
        }
        if (deviceModels.length == 0) {
            deviceModelService.getAll().then(deviceModels => {
                deviceModels.sort((a, b) => {return a.modelName.localeCompare(b.modelName)})
                setDeviceModels(deviceModels)
	        })
        }
        if (actionConfigurations.length == 0) {
            actionConfigurationService.getAll().then(actionConfigurations => {
                actionConfigurations = actionConfigurations.filter(actionConfiguration => actionConfiguration.id != state.id)
                actionConfigurations.sort((a, b) => {return a.name.localeCompare(b.name)})
                setActionConfigurations(actionConfigurations)
	        })
        }
    }, [])

    const handleSensorChange = (currentNode, selectedNodes) => {
        var actionConfigurationSensors = []

        for (var selectedNode of selectedNodes) {
            if (selectedNode.type === 'sensor') {
                actionConfigurationSensors.push(selectedNode.value)
            } else {
                handleNode(_nodeMap.current[selectedNode.value], _nodeMap.current, actionConfigurationSensors)
            }
        }

        handleChange({target: {type: "object", name: "actionConfigurationSensors", value: actionConfigurationSensors}})
        //setSelectedSensors(sensorIds)
    }

    function handleActionTypeChange(action, e) {
		action.type = e.target.value
		switch (action.type) {
			case 'EMAIL':
				action.params = {address: ''}
				break
			case 'HTTP_GET':
				action.params = {url: ''}
				break
			case 'DOWNLINK':
				action.params = {device: devices[0].id}
				break
		}
		handleChange({target: {type: "object", name: "actions", value: state.actions}})
    }

    function handleConditionTypeChange(condition, e) {
		condition.type = e.target.value
		switch (condition.type) {
			case 'VALUE':
				condition.params = {result_type: 'temperature', operator: 'GREATER_THAN', value: 0}
				break
			case 'TRIGGER_TIME':
				condition.params = {milliseconds: 3600000}
				break
			case 'TRIGGER_COUNT':
				condition.params = {count: 1}
				break
			case 'PREV_ACTION':
				condition.params = {when: 'PREVIOUS'}
				break
		}
		handleChange({target: {type: "object", name: "conditions", value: state.conditions}})
    }

    function handleActionParamsChange(action, e) {
		action.params[e.target.name] = e.target.value
		handleChange({target: {type: "object", name: "actions", value: state.actions}})
    }

    function handleConditionParamsChange(condition, e) {
		condition.params[e.target.name] = e.target.value
		handleChange({target: {type: "object", name: "conditions", value: state.conditions}})
    }

    function addAction(e) {
        e.preventDefault()
        let newAction = {
            type: 'EMAIL',
            params: {address: ''}
        }
        let newActions = [...state.actions, newAction]
		handleChange({target: {type: "object", name: "actions", value: newActions}})
    }

    function removeAction(e, index) {
        e.preventDefault()
        let newActions = state.actions.filter((email, i) => i != index)
		handleChange({target: {type: "object", name: "actions", value: newActions}})
    }

    function addCondition(e) {
        e.preventDefault()
        let newCondition = {
            type: 'VALUE',
            params: {result_type: 'temperature', operator: 'GREATER_THAN', value: 0}
        }
        let newConditions = [...state.conditions, newCondition]
		handleChange({target: {type: "object", name: "conditions", value: newConditions}})
    }

    function removeCondition(e, index) {
        e.preventDefault()
        let newConditions = state.conditions.filter((condition, i) => i != index)
		handleChange({target: {type: "object", name: "conditions", value: newConditions}})
    }

    function isBusyLight(deviceId) {
        var device = devices.find(device => device.id == deviceId)
        if (device) {
            var deviceModel = deviceModels.find(deviceModel => device.model == deviceModel.id)
            if (deviceModel && deviceModel.modelName == 'Busylight') {
                return true
            }
        }
        return false
    }

    function handleNode(node, nodeMap, actionConfigurationSensors) {
        for (var sensorId of nodeMap[node.id].sensors) {
            actionConfigurationSensors.push(_sensorMap.current[sensorId])
        }
        for (var childNode of nodeMap[node.id].children) {
            handleNode(nodeMap[childNode], nodeMap, actionConfigurationSensors)
        }
    }

    function buildNodeTree(nodeId, nodeMap, sensorMap) {
        var node = nodeMap[nodeId]
        var childList = []
        if (node.children.length > 0) {
            childList = node.children.map(childId => buildNodeTree(childId, nodeMap, sensorMap))
            childList.sort((a, b) => a.label.localeCompare(b.label))
        }

		var sensorList = []
        for (var sensorId of node.sensors) {
            var sensor = sensorMap[sensorId]
            sensorList.push({
                label: sensor.sensorName,
                value: sensor,
                type: 'sensor',
                checked: sensor.id ? true : false
            })
        }
        if (sensorList.length > 0) {
            sensorList.sort((a, b) => a.label.localeCompare(b.label))
            childList.push(...sensorList)
        }

        var allChildrenDisabled = childList.every(child => child.disabled)

        return {
            label: node.name,
            value: node.id,
            type: 'node',
            children: childList,
            checked: false,
            disabled: allChildrenDisabled || childList.length == 0
        }
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Row>
                <Col>
                    <Form.Group controlId="name">
		                <Form.Label>
		                    {t('actionconfiguration.columns.name')}
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('actionconfiguration.columns.name')} name="name" value={state.name} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="active">
		                <Form.Check type="checkbox" name="active" label={t('actionconfiguration.columns.active')} checked={state.active} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="repeatable">
		                <Form.Check type="checkbox" name="repeatable" label={t('actionconfiguration.columns.repeatable')} checked={state.repeatable} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group>
		                <Form.Label>
		                    {t('actionconfiguration.columns.sensors')}
		                </Form.Label>
		                <DropdownContainer data={nodes} onChange={handleSensorChange} className="bootstrap-treeselect"/>
		            </Form.Group>
		        </Col>
		        <Col>
		            <Form.Group controlId="conditions">
		                <Form.Label>
		                    {t('actionconfiguration.columns.conditions')}
		                </Form.Label>
		                { state.conditions.map((condition, index) =>
			                <Card key={index} style={{marginBottom: '1rem'}}>
			                    <Card.Body>
			                        <Card.Title>
				                        {t('actionconfigurationcondition.label') + ' ' + (index + 1)}
				                        <CloseButton variant="white" onClick={e => removeCondition(e, index)}/>
				                    </Card.Title>
			                        <Form.Label>
					                    {t('actionconfigurationcondition.columns.type')}
					                </Form.Label>
									<Form.Control as="select" name="type" value={condition.type} onChange={e => handleConditionTypeChange(condition, e)}>
										<option value="VALUE">{t('actionconfigurationcondition.types.value')}</option>
										<option value="TRIGGER_TIME">{t('actionconfigurationcondition.types.triggertime')}</option>
										<option value="TRIGGER_COUNT">{t('actionconfigurationcondition.types.triggercount')}</option>
										<option value="PREV_ACTION">{t('actionconfigurationcondition.types.prevaction')}</option>
									</Form.Control>
									{ condition.type == 'VALUE' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.resulttype')}
							                </Form.Label>
											<Form.Control as="select" name="result_type" value={condition.params['result_type']} onChange={e => handleConditionParamsChange(condition, e)}>
												{ resultTypes.map(resultType =>
													<option key={resultType} value={resultType}>{resultType}</option>
												)}
											</Form.Control>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.operator')}
							                </Form.Label>
											<Form.Control as="select" name="operator" value={condition.params['operator']} onChange={e => handleConditionParamsChange(condition, e)}>
												<option value="GREATER_THAN">
													{t('actionconfigurationcondition.operators.greaterthan')}
												</option>
												<option value="LESS_THAN">
													{t('actionconfigurationcondition.operators.lessthan')}
												</option>
												<option value="EQUALS">
													{t('actionconfigurationcondition.operators.equals')}
												</option>
											</Form.Control>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.value')}
							                </Form.Label>
											<Form.Control required type="number" name="value" value={condition.params['value']} onChange={e => handleConditionParamsChange(condition, e)} />
										</>
									}
									{ condition.type == 'TRIGGER_TIME' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.milliseconds')}
							                </Form.Label>
											<Form.Control required type="number" name="milliseconds" value={condition.params['milliseconds']} onChange={e => handleConditionParamsChange(condition, e)} />
										</>
									}
									{ condition.type == 'TRIGGER_COUNT' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.count')}
							                </Form.Label>
											<Form.Control required type="number" name="count" value={condition.params['count']} onChange={e => handleConditionParamsChange(condition, e)} />
										</>
									}
									{ condition.type == 'PREV_ACTION' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.actionconfiguration')}
							                </Form.Label>
											<Form.Control required as="select" name="action_configuration" value={condition.params['action_configuration']} onChange={e => handleConditionParamsChange(condition, e)}>
												<option value="">{t('actionconfigurationcondition.choose')}</option>
												{ actionConfigurations.map(actionConfiguration =>
													<option key={actionConfiguration.id} value={actionConfiguration.id}>{actionConfiguration.name}</option>
												)}
											</Form.Control>
											<Form.Label>
							                    {t('actionconfigurationcondition.params.when')}
							                </Form.Label>
											<Form.Control required as="select" name="when" value={condition.params['when']} onChange={e => handleConditionParamsChange(condition, e)}>
												<option value="PREVIOUS">{t('actionconfigurationcondition.when.previous')}</option>
												<option value="ANY">{t('actionconfigurationcondition.when.any')}</option>
											</Form.Control>
										</>
									}
			                    </Card.Body>
							</Card>
						)}
						<Button
		                    onClick={(e) => addCondition(e)}
		                    title={t('actionconfiguration.buttons.addcondition')}
		                    color="info"
		                    variant="outline-primary">
		                    <FontAwesomeIcon icon={faPlus}/> {t('actionconfiguration.buttons.addcondition')}
		                </Button>
		            </Form.Group>
				</Col>
				<Col>
		            <Form.Group controlId="actions">
		                <Form.Label>
		                    {t('actionconfiguration.columns.actions')}
		                </Form.Label>
		                { state.actions.map((action, index) =>
		                    <Card key={index} style={{marginBottom: '1rem'}}>
			                    <Card.Body>
			                        <Card.Title>
				                        {t('actionconfigurationaction.label') + ' ' + (index + 1)}
				                        <CloseButton variant="white" onClick={e => removeAction(e, index)}/>
				                    </Card.Title>
			                        <Form.Label>
					                    {t('actionconfigurationcondition.columns.type')}
					                </Form.Label>
									<Form.Control as="select" name="type" value={action.type} onChange={e => handleActionTypeChange(action, e)}>
										<option value="EMAIL">{t('actionconfigurationaction.types.email')}</option>
										<option value="HTTP_GET">{t('actionconfigurationaction.types.httpget')}</option>
										<option value="DOWNLINK">{t('actionconfigurationaction.types.downlink')}</option>
									</Form.Control>
									{ action.type == 'EMAIL' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationaction.params.address')} *
							                </Form.Label>
											<Form.Control required type="email" name="address" value={action.params['address']} onChange={e => handleActionParamsChange(action, e)} />
											<Form.Label>
							                    {t('actionconfigurationaction.params.subject')}
							                </Form.Label>
											<Form.Control type="text" name="subject" value={action.params['subject']} onChange={e => handleActionParamsChange(action, e)} />
											<Form.Label>
							                    {t('actionconfigurationaction.params.text')}
							                </Form.Label>
							                <Form.Control as="textarea" rows={3} placeholder={t('actionconfigurationaction.params.text')} name="text" value={action.params['text'] || ''} onChange={e => handleActionParamsChange(action, e)} />
										</>
									}
									{ action.type == 'HTTP_GET' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationaction.params.url')} *
							                </Form.Label>
											<Form.Control required type="text" name="url" value={action.params['url']} onChange={e => handleActionParamsChange(action, e)} />
										</>
									}
									{ action.type == 'DOWNLINK' &&
										<>
											<Form.Label>
							                    {t('actionconfigurationaction.params.device')} *
							                </Form.Label>
											<Form.Control as="select" name="device" value={action.params['device']} onChange={e => handleActionParamsChange(action, e)}>
												{ devices.map(device =>
													<option key={device.id} value={device.id}>{device.name}</option>
												)}
											</Form.Control>
											<Form.Label>
							                    {t('actionconfigurationaction.params.payload')} *
							                </Form.Label>
							                { isBusyLight(action.params['device']) &&
							                    <Form.Control required as="select" name="payload" value={action.params['payload']} onChange={e => handleActionParamsChange(action, e)}>
													<option value="">Välj färg</option>
													<option value="FF0000FF00">Fast röd</option>
													<option value="0000FFFF00">Fast grön</option>
													<option value="00FF00FF00">Fast blå</option>
													<option value="FF00000A0A">Blinkande röd</option>
													<option value="0000FF0A0A">Blinkande grön</option>
													<option value="00FF000A0A">Blinkande blå</option>
												</Form.Control>
							                }
							                { !isBusyLight(action.params['device']) &&
							                    <Form.Control required type="text" name="payload" value={action.params['payload']} onChange={e => handleActionParamsChange(action, e)} />
							                }
											<Form.Label>
							                    {t('actionconfigurationaction.params.port')} *
							                </Form.Label>
											<Form.Control required type="number" name="port" value={action.params['port']} onChange={e => handleActionParamsChange(action, e)} />
										</>
									}
			                    </Card.Body>
							</Card>
		                )}
		                <Button
		                    onClick={(e) => addAction(e)}
		                    title={t('actionconfiguration.buttons.addaction')}
		                    color="info"
		                    variant="outline-primary">
		                    <FontAwesomeIcon icon={faPlus}/> {t('actionconfiguration.buttons.addaction')}
		                </Button>
		            </Form.Group>
		        </Col>
	        </Row>
        </Form>
    )
}

export default ActionConfigurationForm