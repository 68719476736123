import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { Button } from 'react-bootstrap'
import DeleteForm from './deleteform.component'
import { useTranslation } from "react-i18next";

function DeleteButton(props) {

    const {t, i18n} = useTranslation('common');
    const [show, setShow] = useState(false)

    function handleConfirm () {
        setShow(false)
        props.confirmClick(props.data)
    }

    return (
        <>
        <Button 
            onClick={() => setShow(true)}
            title="Delete" 
            size="sm" 
            color="info" 
            variant="outline-danger">
            <FontAwesomeIcon icon={faTrash}/>
        </Button>
        <ModalForm
            data={props.data}
            show={show}
            handleConfirm={handleConfirm}
            handleClose={() => setShow(false)} 
            modalHeader={t("crudtable.delete.header")}
            modalBody={<DeleteForm />}
        />
        </>
    )

}

export default DeleteButton