import React, { useState, useEffect } from 'react';
import CrudTable from '../table/crudtable'
import { deviceMetadataService, accessTagService, deviceModelService, authService, environmentService } from '../../_services'
import DeviceForm from './devicemetadataform.component'
import { useTranslation } from "react-i18next";

function DeviceMetadata() {

	const [accessTags, setAccessTags] = useState([])
	const [deviceModels, setDeviceModels] = useState([])
	const {t} = useTranslation('common');

	const initialState = {
		accessTag: '',
		name: '',
		ref: '',
		networkType: 'LORAWAN',
		params: { devEUI: '', defaultAppKey: '', defaultAppEUI: '', serialNumber: '' },
		model: ''
	}

	useEffect(() => {
		console.log('useEffect')
		if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
            })
		}
		if (deviceModels.length == 0) {
			deviceModelService.getAll().then(deviceModels => {
                setDeviceModels(deviceModels)
            })
		}
    }, [])

    const accessTagFormatter = function(cell) {
        var accessTag = accessTags.find((accessTag) => accessTag.id == cell)
        if (accessTag) {
			return accessTag.name
        }
        return cell
    }

    const modelFormatter = function(cell) {
        var deviceModel = deviceModels.find((deviceModel) => deviceModel.id == cell)
        if (deviceModel) {
			return deviceModel.modelName
        }
        return cell
    }

    const paramsFormatter = function(cell) {
		if (cell) {
			var params = ''
			for (const [key, value] of Object.entries(cell)) {
	            params += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value || '-'} `
	        }
	        return params
		}
		return '-'
	}

	const isHidden = () => {
		return environmentService.loadEnvironment() == 'production_wexnet'
    }

	const columns = [
        {
			dataField: 'model',
			text: t('devicemetadata.columns.model'),
			formatter: modelFormatter,
			sort: true,
            headerStyle: { width: '15%' }
		},
		{
			dataField: 'networkType',
			text: t('devicemetadata.columns.networktype'),
			sort: true,
            headerStyle: { width: '15%' },
            hidden: isHidden()
		},
		{
			dataField: 'accessTag',
			text: t('devicemetadata.columns.accesstag'),
			formatter: accessTagFormatter,
			sort: true,
            headerStyle: { width: '15%' }
		},
		{
			dataField: 'params',
			text: t('devicemetadata.columns.params'),
			formatter: paramsFormatter,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('devicemetadata.label')}
			</h2>
			{ (accessTags.length > 0 && deviceModels.length > 0) &&
				<CrudTable
					service={deviceMetadataService}
					columns={columns}
					initialState={initialState}
					form={<DeviceForm />}
					sort={{dataField: 'name', order: 'asc'}}
					creatable={authService.hasRoles(["ROLE_DEVICE_METADATA_WRITE"])}
					editable={authService.hasRoles(["ROLE_DEVICE_METADATA_WRITE"])}
					deletable={authService.hasRoles(["ROLE_DEVICE_METADATA_WRITE"])}
				/>
			}
		</div>
	)
}

export default DeviceMetadata;