import api from './api.service'

export const apiCredentialService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/apiCredentials/'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function save(apiCredential) {
    return api.post(BASE_URL, apiCredential)
}

function update(id, apiCredential) {
    return api.put(BASE_URL + id, apiCredential)
}

function _delete(id) {
    return api.delete(BASE_URL + id)
}
