import React, { useEffect, useState } from 'react'
import { sensorService, resultService } from '../../_services/'
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

function Lifebuoy() {

	const {t} = useTranslation('common');

	const batteryFormatter = function(cell) {
		if (cell) {
			return cell.total + ' (' + formatDate(cell.unixtime) + ')'
		}
        return '-'
    }

    const digital1Formatter = function(cell) {
        if (cell) {
			return (cell.total > 0 ? 'Ja' : 'Nej') + ' (' + formatDate(cell.unixtime) + ')'
		}
        return '-'
    }

    const removedFormatter = function(cell) {
        if (cell) {
			return (cell.total > 0 ? 'Ja' : 'Nej') + ' (' + formatDate(cell.unixtime) + ')'
		}
        return '-'
    }

	const columns = [
		{
			dataField: 'name',
			text: t('lifebuoy.columns.name'),
			sort: true
		},
		{
			dataField: 'battery',
			text: t('lifebuoy.columns.battery'),
			formatter: batteryFormatter,
			sort: true
		},
		{
			dataField: 'digital_1',
			text: t('lifebuoy.columns.removed'),
			formatter: digital1Formatter,
			sort: true
		},
		{
			dataField: 'removed',
			text: t('lifebuoy.columns.tamperedwith'),
			formatter: removedFormatter,
			sort: true
		}
	]

	const [sensors, setSensors] = useState([])
    const [columnState, setColumns] = useState(columns)

    useEffect(() => {
        if (sensors.length == 0) {
            sensorService.getAll().then(data => {
				var filteredSensors = data.filter(sensor => sensor.type == 'REMOVED')
				console.log(filteredSensors)
	            if (filteredSensors.length > 0) {
	                var promises = []
	                filteredSensors.forEach(sensor => {
	                    promises.push(withTimeout(1000, resultService.getLatestAggregatedResultOfTypeForSensor(sensor.id, 'battery')))
	                    promises.push(withTimeout(1000, resultService.getLatestAggregatedResultOfTypeForSensor(sensor.id, 'digital_1')))
	                    promises.push(withTimeout(1000, resultService.getLatestAggregatedResultOfTypeForSensor(sensor.id, 'removed')))
	                })

	                Promise.allSettled(promises).then((results) => {
						results.forEach(result => {
							if (result.status == 'fulfilled') {
								var sensor = filteredSensors.find(s => s.id == result.value.sensor)
								if (sensor) {
									if (result.value.resultType == 'battery') {
										sensor.battery = result.value
									} else if (result.value.resultType == 'digital_1') {
										sensor.digital_1 = result.value
									} else if (result.value.resultType == 'removed') {
										sensor.removed = result.value
									}
								}
							}
						})

						setSensors(filteredSensors)
                    });
	            }
	            //setSensors(filteredSensors)
	        })
        }
    }, []);

    const withTimeout = (millis, promise) => {
	    const timeout = new Promise((resolve, reject) =>
	        setTimeout(
	            () => reject(`Timed out after ${millis} ms.`),
	            millis));
	    return Promise.race([
	        promise,
	        timeout
	    ]);
	};

	const formatDate = (millis) => {
	    return moment(millis).format('YYYY-MM-DD HH:mm')
	};

	return (
		<div>
			<h2>
				{t('lifebuoy.label')}
			</h2>
            <ToolkitProvider
				bootstrap4
				keyField='id'
				data={sensors}
				columns={columnState}
			>
	            {toolkitprops => (
		            <BootstrapTable
						condensed={true}
						{...toolkitprops.baseProps}
						onTableChange={() => console.log("onTableChange")}
						sort={{dataField: 'name', order: 'asc'}}
					/>
				)}
			</ToolkitProvider>
		</div>
	)
}

export default Lifebuoy;