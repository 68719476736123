import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const AccessTagForm = (props) => {

    const [state, setState] = useState(props.data)
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        setState(props.data)
    }, [props])

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('accesstag.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('accesstag.columns.name')}  name="name" value={state.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="active">
                <Form.Check type="checkbox" label={t('accesstag.columns.active')} name="active" checked={state.active} onChange={handleChange}/>
            </Form.Group>
        </Form>
    )
}

export default AccessTagForm