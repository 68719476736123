import React, {useEffect, useState} from 'react'
import { appLogService } from '../../_services'
import { useTranslation } from "react-i18next";
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow, HeatmapLayer, LatLng } from '@react-google-maps/api';
import moment from 'moment'
import { Form } from 'react-bootstrap'

function AppLogMap() {

    const [appLogs, setAppLogs] = useState([])
	const [map, setMap] = React.useState(null)
	const [selectedAppLog, setSelectedAppLog] = useState(null)
	const [mode, setMode] = useState('MARKERS')
	const {t} = useTranslation('common');

    const containerStyle = {
		width: '100%',
		height: '85vh'
    };

    const onLoad = React.useCallback(function callback(map) {
	    //const bounds = new window.google.maps.LatLngBounds();
	    //map.fitBounds(bounds);
	    setMap(map)
	    appLogService.getAllWithLocation().then(appLogs => {
	        console.log(appLogs)
	        var existingLatLngs = []
	        appLogs.forEach(appLog => {
	            var key = appLog.lat + '-' + appLog.lng
	            while (existingLatLngs.indexOf(key) >= 0) {
	                appLog.lng += 0.00001
	                key = appLog.lat + '-' + appLog.lng
	            }
	            existingLatLngs.push(key)
	        })

	        setAppLogs(appLogs)

	        const bounds = new window.google.maps.LatLngBounds();
	        appLogs.map(appLog => {
	            if (appLog.lat != 1000 && appLog.lng != 1000) {
	                bounds.extend(appLog)
	            }
	        })
	        map.fitBounds(bounds);
	    })
	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	const handleCenter = () => {
	    if (map === null)
	        return
    }

    const options = {
		imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
		maxZoom: 21
    }

    const onMarkerClick = appLog => {
		console.log(appLog)
		setSelectedAppLog(appLog)
    }

    const closeInfoWindow = () => {
        setSelectedAppLog(null)
    }

    const getLatLngs = () => {
        var latLngs = []
        appLogs.forEach(appLog => {
            latLngs.push(new window.google.maps.LatLng(appLog.lat, appLog.lng))
        })
        return latLngs
    }

    const onChangeMode = (e) => {
        setMode(e.target.value)
    }

	return (
		<div>
			<div>
				<h2 className="pull-left">
	                {t('applogmap.label')}
	            </h2>
	            <div className="pull-right">
					<Form.Control as="select" name="mode" value={mode} onChange={onChangeMode}>
	                    <option value="MARKERS">MARKERS</option>
	                    <option value="HEATMAP">HEATMAP</option>
	                </Form.Control>
	            </div>
            </div>
            <GoogleMap
		        mapContainerStyle={containerStyle}
		        onLoad={onLoad}
		        onUnmount={onUnmount}
		        onDragEnd={handleCenter}
		        onZoomChanged={() => console.log(map.getZoom())}
		        options={{ minZoom: 3 }}>
		        { mode === 'MARKERS' &&
		            <MarkerClusterer options={options}>
			            {(clusterer) =>
			                appLogs.filter(appLog => appLog.lat != 1000 && appLog.lng != 1000).map(appLog => (
			                    <Marker
				                    key={appLog.id}
				                    position={appLog}
				                    clusterer={clusterer}
				                    title={appLog.name}
				                    onClick={() => onMarkerClick(appLog)}
				                    marker={appLog}
				                >
				                    { appLog === selectedAppLog &&
				                        <InfoWindow onCloseClick={() => closeInfoWindow()}>
							                <div>
							                    <div>
						                            {moment(appLog.dateCreated).format('YYYY-MM-DD HH:mm:ss')}
						                        </div>
						                        <div>
						                            {appLog.type}
						                        </div>
							                </div>
							            </InfoWindow>
				                    }
			                    </Marker>
			                ))
			            }
	                </MarkerClusterer>
		        }
		        { mode === 'HEATMAP' &&
		            <HeatmapLayer data={getLatLngs()} options={{opacity: 1, radius: 20}}/>
		        }
			</GoogleMap>
        </div>
	)
}

export default AppLogMap;