import React from 'react'
import { SERVER_URL } from '../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faFolder, faGlobe, faEdit, faTrash, faFolderOpen, faFolderPlus, faThermometerHalf, faCity, faRoad, faTachometerAlt, faBatteryHalf, faSignal, faUsers, faRulerVertical, faQuestionCircle, faDrawPolygon, faPlus } from '@fortawesome/free-solid-svg-icons'

export const iconService = {
    getMapIcon,
    getIcon
}

function getMapIcon(sensor) {
	if (sensor.icon) {
        return SERVER_URL + '/icon/show/' + sensor.icon
    }

    var fontAwesomeIcon = getFontAwesomeIcon(sensor)

	return { path: fontAwesomeIcon.icon[4], fillOpacity: 1.0, scale: 0.08 }
}

function getIcon(sensor) {
	if (sensor.icon) {
        return <img src={SERVER_URL + '/icon/show/' + sensor.icon} style={{width: '25px'}}/>
    }

    var fontAwesomeIcon = getFontAwesomeIcon(sensor)

    return <FontAwesomeIcon icon={fontAwesomeIcon} style={{width: 25}}/>
}

function getFontAwesomeIcon(sensor) {
    switch (sensor.type) {
        case 'TEMPERATURE':
            return faThermometerHalf
        case 'SPEED':
            return faTachometerAlt
        case 'BATTERY':
            return faBatteryHalf
        case 'RSSI':
            return faSignal
        case 'SNR':
            return faSignal
        case 'CURRENT':
        case 'NEW':
        case 'REMOVED':
            return faUsers
        case 'DEPTH':
            return faRulerVertical
        case 'OTHER':
            return faQuestionCircle
    }
    return faFolder
}
