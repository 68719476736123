import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Form from "react-validation/build/form";
import { Container, Row, Jumbotron, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { userActions } from '../_actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from './alert.component'
import { useTranslation } from "react-i18next";
import { environmentService } from '../_services'

function Login() {

	const {t} = useTranslation('common');
	const [environment, setEnvironment] = useState('')

	useEffect(() => {
		console.log('useEffect')
		var existingEnvironment = environmentService.loadEnvironment()
		console.log(existingEnvironment)
		if (existingEnvironment) {
			setEnvironment(existingEnvironment)
		} else {
			environmentService.get().then(environment => {
	            console.log(environment)
	            setEnvironment(environment)
	            environmentService.saveEnvironment(environment)
	        })
		}
    }, [])

  const required = value => {
    if (submitted && !value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const [inputs, setInputs] = useState({
    username: '',
    password: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const loggingIn = useSelector(state => state.authentication.loggingIn);

  const dispatch = useDispatch();
  const location = useLocation();
  
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleLogin(e) {
    e.preventDefault();

    setSubmitted(true);
    if (username && password) {
        // get return url from location state or default to home page
        const { from } = location.state || { from: { pathname: "/" } };
        dispatch(userActions.login(username, password, from));
    }

   }

   function getLogo() {
		switch (environment) {
		    case 'development':
		    case 'production':
		        return <img src="logos/iqmtel.png" style={{width: '100%', marginBottom: '25px'}}/>
		    case 'production_wexnet':
		        return <img src="logos/wexnet.png" style={{width: '100%', marginBottom: '25px'}}/>
		}
		return <img src="logos/iqmtel.png" style={{width: '100%', marginBottom: '25px'}}/>
	}

    return (
      <Container className="auth-wrapper">
        <Row>
          <Col md={{ span: 4, offset: 4 } }>
            <Jumbotron className="auth-inner">
            <AlertComponent />
            {getLogo()}
            <Form onSubmit={handleLogin}>
              <FormGroup controlId="formBasicUsername">
                <FormLabel>
					{t('login.username')}
				</FormLabel>
                <FormControl 
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={handleChange}
                  validations={[required]}
                />
              </FormGroup>
          
              <FormGroup controlId="formBasicPassword">
                <FormLabel>
					{t('login.password')}
				</FormLabel>
                <FormControl 
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  validations={[required]}
                />
              </FormGroup>

              <FormGroup controlId="formLogin">
                <button
                  className="btn btn-primary btn-block"
                  disabled={loggingIn}
                >
                  <span>
                    {t('login.login')}
                  </span>
                  {loggingIn && (
                    <>
                    &nbsp;
                    <span className="spinner-border spinner-border-sm"></span>
                    </>
                  )}
                </button>
              </FormGroup>
            </Form>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );
}

export default Login;