import React, { useState, useEffect } from 'react';
import CrudTable from '../table/crudtable'
import { categoryService, accessTagService, authService } from '../../_services'
import CategoryForm from './categoryform.component'
import { useTranslation } from "react-i18next";
import { SERVER_URL } from '../../config';

function Category() {

	const [accessTags, setAccessTags] = useState([])
	const {t} = useTranslation('common');

	const initialState = {
		accessTag: '',
		name: '',
		description: '',
		position: 0,
		public_: false
	}

	useEffect(() => {
		if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
            })
		}
    }, [])

    const accessTagFormatter = function(cell) {
        var accessTag = accessTags.find((accessTag) => accessTag.id == cell)
        if (accessTag) {
			return accessTag.name
        }
        return cell
    }

    const descriptionFormatter = function(cell) {
        return cell || '-'
    }

    const publicFormatter = function(cell) {
        return cell ? t('crudtable.yes') : t('crudtable.no')
    }

	const columns = [
		{
			dataField: 'accessTag',
			text: t('category.columns.accesstag'),
			formatter: accessTagFormatter,
			sort: true
		},
		{
			dataField: 'position',
			text: t('category.columns.position'),
			sort: true
		},
		{
			dataField: 'name',
			text: t('category.columns.name'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('category.columns.description'),
			formatter: descriptionFormatter,
			sort: true
		},
		{
			dataField: 'public_',
			text: t('category.columns.public'),
			formatter: publicFormatter,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('category.label')}
			</h2>
			{ accessTags.length > 0 &&
				<CrudTable
					service={categoryService}
					columns={columns}
					initialState={initialState}
					form={<CategoryForm />}
					creatable={authService.hasRoles(["ROLE_SENSOR_ICON_WRITE"])}
					editable={authService.hasRoles(["ROLE_SENSOR_ICON_WRITE"])}
					deletable={authService.hasRoles(["ROLE_SENSOR_ICON_WRITE"])}
				/>
			}
		</div>
	)
}

export default Category;