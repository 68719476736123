import api from './api.service'

export const nodeTypeService = {
    getAll
};

const BASE_URL = '/nodeTypes/'

function getAll() {
    return api.get(BASE_URL)
}
