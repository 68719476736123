import React, {useEffect, useState} from 'react'
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import { sensorService, resultService, iconService } from '../../_services/'
import { SERVER_URL } from '../../config'
import { Modal, Button } from 'react-bootstrap'
import SockJsClient from 'react-stomp';
import { useTranslation } from "react-i18next";

function SensorMap() {

  const [sensors, setSensors] = useState([])
  const [topics, setTopics] = useState([])
  const [map, setMap] = React.useState(null)
  const [show, setShow] = useState(false)
  const [selectedSensor, setSelectedSensor] = useState(null)
  const [results, setResults] = useState([])
  const [client, setClient] = useState(null)
  const {t} = useTranslation('common');

  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  useEffect(() => {
  }, [sensors]);

  useEffect(() => () => {
    if (client) {
		client.disconnect()
    }
  }, [client]);

  const containerStyle = {
      width: '100%',
      height: '90vh'
    };
    /*  
    const center = {
      lat: 56.888523,
      lng: 14.800766
    };
  */
  function handleCenter() {
    if (map === null)
        return 
    //const newPos = map.getCenter().toJSON();

  }
 
  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map)
    console.log('onLoad')
    sensorService.getAllMarkers().then( sensors => {
        setSensors(sensors)

        const bounds = new window.google.maps.LatLngBounds();
        sensors.map(sensor => {
			bounds.extend(sensor)
        })
        console.log('bounds')
        console.log(bounds)
        map.fitBounds(bounds);

        var topics = []
        sensors.map((sensor) => {
            topics.push('/topic/sensor/' + sensor.id)
        })
        console.log(topics)
        setTopics(topics)
    })
  }, [])
 
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onMarkerLoad = marker => {
    console.log(marker)
  }

	const onSensorClick = sensor => {
		console.log(sensor)
		setSelectedSensor(sensor)
		resultService.resultsForSensor(sensor).then( results => {
          setResults(results)
          setShow(true)
        })
    }

    const onMessage = message => {
        console.log(message)
        setSensors(prevSensors => {
            return prevSensors.map((prevSensor) => {
                if (prevSensor.id === message.id) {
                    return message
                } else {
                    return prevSensor
                }
            })
        })
      }

  const onRef = ref => {
    console.log(ref)
    if (ref) {
        setClient(ref)
    }
  }
 
  return (
    <div style={{marginLeft: -30, marginRight: -30}}>
        <SockJsClient url={SERVER_URL + '/stomp'} topics={topics}
            onMessage={onMessage}
            options={{transports: ['websocket']}}
            ref={ onRef}/>
	      <GoogleMap
	        mapContainerStyle={containerStyle}
	        onLoad={onLoad}
	        onUnmount={onUnmount}
	        onDragEnd={handleCenter}
	      >
	          <MarkerClusterer options={options}>
	            {(clusterer) =>
	                sensors.map((sensor) => (
	                   <Marker
	                    key={sensor.id}
	                    position={sensor}
	                    clusterer={clusterer}
	                    onLoad={onMarkerLoad}
	                    title={sensor.name}
	                    //icon={SERVER_URL + '/icon/show/' + sensor.icon}
	                    //icon={{ path: faGlobe.icon[4], fillOpacity: 1.0, scale: 0.1 }}
	                    icon={iconService.getMapIcon(sensor)}
	                    onClick={() => onSensorClick(sensor)}
	                    marker={sensor}
	                  />
	                ))
	            }
            </MarkerClusterer>
	      </GoogleMap>
	    <Modal
          show={show}
          backdrop={false}>
            <Modal.Header>
                <Modal.Title>{selectedSensor ? selectedSensor.name : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
				{ selectedSensor &&
					<>
						{ results.length > 0 &&
							<div>
								<ul>
									{ results.map(result => {
										return (
											<>
												{ Object.keys(result.params).map(key => {
													return <li key={key}>{key}: {result.params[key]}</li>
												})}
											</>
										)
									})}
								</ul>
							</div>
						}
						{ results.length == 0 &&
							<>
								{t('map.noresults')}
							</>
						}
					</>
				}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}
 
export default SensorMap;