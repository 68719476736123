import React from 'react';
import CrudTable from '../table/crudtable'
import { customerAccessTagService } from '../../_services'
import CustomerAccessTagForm from './customeraccesstagform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function CustomerAccessTag() {

  const {t} = useTranslation('common');

  const initialState = {
      customer: -1,
      accessTag: -1,
      expireDate: moment(new Date()).format('YYYY-MM-DD')
  }

  const formatBoolean = function(cell) {
    return cell ? t('crudtable.yes') : t('crudtable.no')
  }

  const dateFormatter = function(cell) {
    return moment(cell).format('YYYY-MM-DD')
  }

  const columns = [
    {
      dataField: 'customerName',
      text: t('customeraccesstag.columns.customer'),
      sort: true
    },
    {
      dataField: 'accessTagName',
      text: t('customeraccesstag.columns.accesstag'),
      sort: true
    },
    {
      dataField: 'expireDate',
      text: t('customeraccesstag.columns.expiredate'),
      formatter: dateFormatter,
      sort: true
    },
    {
      dataField: 'owner',
      text: t('customeraccesstag.columns.owner'),
      formatter: formatBoolean,
      sort: true
    }
  ]

  return (
    <div>
      <h2>
        {t('customeraccesstag.label')}
      </h2>
      <CrudTable
        service={customerAccessTagService}
        columns={columns}
        initialState={initialState}
        form={<CustomerAccessTagForm />}
        remote={{"filter": false, "search": false, "pagination": false}}
        sort={{dataField: 'customerName', order: 'asc'}}
      />
      </div>
  )
}

export default CustomerAccessTag;