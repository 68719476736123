
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import EditButton from './editbutton';
import DeleteButton from './deletebutton';
import AddButton from './addbutton';
import {Spinner} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function CrudTable({service, serviceMethod, initialState, form, columns, onTableChange = () => console.log("onTableChange"), onSave, creatable = true, editable = true, deletable = true, sort, size}, remote =  { filter: false, search: false, pagination: false }) {
  const [items, setItems] = useState([]);
  const [columnState, setColumns] = useState(columns)
  const dispatch = useDispatch();
  const {t} = useTranslation('common');
  const [loading, setLoading] = useState(false);

  const NoDataIndication = () => (
    <div>
        {loading &&
		    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'50px', marginBottom:'50px'}}>
		      <Spinner animation="border" role="status">
		        <span className="sr-only">Loading...</span>
		      </Spinner>
		    </div>
	    }
	    {!loading &&
	        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'10px', marginBottom:'10px'}}>
              <span>Ingen data hittades</span>
            </div>
	    }
    </div>
  );

	useEffect(() => {
		function editFormatter(cell, row, rowIndex, formatExtraData) {
		  return (
		      <span style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
		        { editable &&
			        <EditButton
                      data={row}
                      confirmClick={onUpdate}
                      form={form}
                      size={size || 'md'}
                    />
		        }
		        { editable && deletable &&
		            <span>&nbsp;&nbsp;</span>
		        }
		        { deletable &&
			        <DeleteButton
			          data={row}
			          confirmClick={onDelete}
			        />
		        }
		      </span>
		  )
		}

		if (editable || deletable) {
      setColumns([
          ...columns,
          {
            dataField: "edit",
            text: "",
            sort: false,
            formatter: editFormatter,
            headerAttrs: { width: (editable && deletable) ? 85 : 40 },
            attrs: { width: (editable && deletable) ? 85 : 40, className: "EditRow" }
          }
        ]
      )
		}

		getAll()
	}, [columns])
  
    const getAll = () => {
      setLoading(true)
      if (serviceMethod) {
	    serviceMethod().then(data => {
	      setItems(data)
	      setLoading(false)
	    })
      } else {
        service.getAll().then(data => {
	      setItems(data)
	      setLoading(false)
	    })
      }
    }
  
    const onUpdate = row => {
      service.update(row.id, row).then( result => {
        dispatch(alertActions.success("Update Successful"))
        
        setItems( prevItems => prevItems.map(item => {
          if (item.id === row.id) {
            return row;
          }
      
          return item;
        }))
      })
    }
  
    function onDelete(row) {
      service._delete(row.id).then(data => {
          setItems( prevItems => prevItems.filter(item => {
              return item.id !== row.id
          }))
          dispatch(alertActions.success("Delete Successful"))
      })  
    }
  
    function onAdd(row) {
      service.save(row).then(data =>  {
          dispatch(alertActions.success("Create Successful"))
//          const newItems = items.concat(data)
//          setItems(newItems)
			getAll()
          if (onSave) {
            onSave(data)
          }
      })
    }

  const pagination = paginationFactory({
      sizePerPage: 25,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
          console.log('page', page);
          console.log('sizePerPage', sizePerPage);
      },
      onSizePerPageChange: function (page, sizePerPage) {
          console.log('page', page);
          console.log('sizePerPage', sizePerPage);
      },
      paginationTotalRenderer: (from, to, size) => {
          return ' ' + t('crudtable.total', {from: from, to: to, size: size})
      },
      nextPageTitle: t('crudtable.next'), // the title of next page button
      prePageTitle: t('crudtable.previous'), // the title of previous page button
      firstPageTitle: t('crudtable.first'), // the title of first page button
      lastPageTitle: t('crudtable.last')
  })

  const { SearchBar } = Search;

  return (
    <div>
    <ToolkitProvider
      bootstrap4
      keyField='id'
      data={items}
      columns={columnState}
      search
    >
      {
        toolkitprops => (
          <div>
              <SearchBar {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
              { creatable &&
                  <AddButton
		              data={initialState}
		              confirmClick={onAdd}
		              form={form}
                      size={size || 'md'}
		          />
              }
              <BootstrapTable
                  remote={remote}
                  noDataIndication={ () => <NoDataIndication /> }
                  condensed={true} 
                  pagination={pagination}
                  {...toolkitprops.baseProps}
                  onTableChange={onTableChange}
                  sort={sort}
              />
          </div>
          )
      }
      </ToolkitProvider>
      </div>
  )
}

export default CrudTable

