import api from './api.service'

export const actionEventService = {
    getAll,
    _delete
};

const BASE_URL = '/actionEvents/'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 1000, sort: 'dateCreated', order: 'desc'})
}

function _delete(id) {
    return api.delete(BASE_URL + id)
}
