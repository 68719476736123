import './css/bootstrap.min.css'
import './css/App.css'
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from "./translations/en/common.json";
import common_sv from "./translations/sv/common.json";
import { LoadScript } from '@react-google-maps/api';

i18next
	.use(LanguageDetector)
	.init({
		interpolation: { escapeValue: false },
        fallbackLng: 'en',
        resources: {
            sv: {
                common: common_sv
            },
            en: {
                common: common_en
            }
        }
	});

i18next.changeLanguage()

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<LoadScript googleMapsClientId="gme-iqmtelswedenab" libraries={["visualization"]} >
	        <Provider store={store}>
	            <App />
	        </Provider>
        </LoadScript>
    </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();