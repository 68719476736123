import React from 'react'
import {authService} from '../../_services/'

const IfAnyGranted = ({ children, roles }) => {
    const hasPermission = authService.hasAnyRole(roles);
    return (
      <>
        {hasPermission && children}
      </>
    );
  };

export default IfAnyGranted