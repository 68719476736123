import React from 'react'
import { resultService } from '../../_services/'
import CrudTable from '../table/crudtable'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function Result() {

	const {t} = useTranslation('common');

	const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const formatJson = function(cell) {
	    var params = ''
	    for (const key of Object.keys(cell).sort()) {
	        params += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${cell[key]} `
	    }
		return params
    }

	const sensorFormatter = function(cell) {
		return cell || '-'
	}

	const initialState = {
		deviceRef: '',
		dateCreated: '',
		network: '',
		params: ''
	}

	const columns = [
		{
			dataField: 'deviceRef',
			text: t('result.columns.deviceref'),
			sort: true
		},
		{
			dataField: 'sensorName',
			text: t('result.columns.sensor'),
			formatter: sensorFormatter,
			sort: true
		},
		{
			dataField: 'dateCreated',
			text: t('result.columns.dateCreated'),
			sort: true,
			formatter: formatDate
		},
		{
			dataField: 'network',
			text: t('result.columns.network'),
			sort: true
		},
		{
			dataField: 'params',
			text: t('result.columns.params'),
			formatter: formatJson,
			filterValue: formatJson
		}
	]

  return (
    <div>
      <h2>
        {t('result.label')}
      </h2>
      <CrudTable
        service={resultService}
        columns={columns}
        initialState={initialState}
        form={<></>}
        creatable={false}
        editable={false}
        deletable={false}
      />
      </div>
  )
}

export default Result;