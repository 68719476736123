import React, {useEffect} from 'react';
import { authService } from '../_services'
import { history } from '../_helpers';

function Home() {

	useEffect(() => {
		if (authService.hasRoles(['ROLE_NODE_READ'])) {
			history.push("/node")
		}
    }, [])

	return (
		<h2>Home</h2>
	)
}

export default Home;