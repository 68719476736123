import React, {useState, useEffect} from 'react';
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { fiware } from '../../_constants'

function DeviceModelComponent({data, handleChange, handleSubmit, validated}) {

  const {t} = useTranslation('common');

  return (
    <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
        <Form.Group controlId="category">
          <Form.Label>
            {t('devicemodel.columns.category')}
          </Form.Label>
          <Select placeholder={t('devicemodel.select.category')} options={fiware.categories} value={data.category} getOptionLabel={category => category} getOptionValue={category => category} isMulti={true} closeMenuOnSelect={false} onChange={(value) => handleChange({target: {type: "select", name: "category", value: value}})}/>
        </Form.Group>
        <Form.Group controlId="controllerProperty">
            <Form.Label>
                {t('devicemodel.columns.controlledproperty')}
            </Form.Label>
            <Select placeholder={t('devicemodel.select.controlledproperty')} options={fiware.controlledProperties} value={data.controlledProperty} getOptionLabel={controlledProperty => controlledProperty} getOptionValue={controlledProperty => controlledProperty} isMulti={true} closeMenuOnSelect={false} onChange={(value) => handleChange({target: {type: "select", name: "controlledProperty", value: value}})}/>
        </Form.Group>
        <Form.Group controlId="manufacturerName">
            <Form.Label>
                {t('devicemodel.columns.manufacturername')}
            </Form.Label>
            <Form.Control required type="text" placeholder={t('devicemodel.columns.manufacturername')} name="manufacturerName" value={data.manufacturerName} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="brandName">
            <Form.Label>
                {t('devicemodel.columns.brandname')}
            </Form.Label>
            <Form.Control required type="text" placeholder={t('devicemodel.columns.brandname')} name="brandName" value={data.brandName} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="modelName">
            <Form.Label>
                {t('devicemodel.columns.modelname')}
            </Form.Label>
            <Form.Control required type="text" placeholder={t('devicemodel.columns.modelname')} name="modelName" value={data.modelName} onChange={handleChange} />
        </Form.Group>
    </Form>
  );
}

export default DeviceModelComponent