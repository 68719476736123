import React, {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap'

function DownlinkTest() {

	const sendPayload = (payload) => {
		fetch("data/downlink/test?payload=" + payload)
    }

    return (
        <div>
			<Button
                onClick={e => sendPayload('FF0000FF00')}
                variant="danger"
                style={{width: '200px'}}>
                RÖD FAST
            </Button>
            <br/>
            <Button
                onClick={e => sendPayload('0000FFFF00')}
                variant="success"
                style={{width: '200px'}}>
                GRÖN FAST
            </Button>
            <br/>
            <Button
                onClick={e => sendPayload('00FF00FF00')}
                variant="primary"
                style={{width: '200px'}}>
                BLÅ FAST
            </Button>
            <br/>
            <Button
                onClick={e => sendPayload('FF00000A0A')}
                variant="danger"
                style={{width: '200px'}}>
                RÖD BLINKANDE
            </Button>
            <br/>
            <Button
                onClick={e => sendPayload('0000FF0A0A')}
                variant="success"
                style={{width: '200px'}}>
                GRÖN BLINKANDE
            </Button>
            <br/>
            <Button
                onClick={e => sendPayload('00FF000A0A')}
                variant="primary"
                style={{width: '200px'}}>
                BLÅ BLINKANDE
            </Button>
        </div>
    )
}

export default DownlinkTest;