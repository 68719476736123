import api from './api.service'

export const appLogService = {
    getAll,
    getAllWithLocation,
    save,
    update,
    _delete
};

const BASE_URL = '/appLogs'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 500, sort: 'dateCreated', order: 'desc'})
}

function getAllWithLocation(max) {
    return api.get(BASE_URL, {max : max || 500, sort: 'dateCreated', order: 'desc', withLocation: true})
}

function save(appLog) {
    return api.post(BASE_URL, appLog)
}

function update(id, appLog) {
    return api.put(BASE_URL + "/" + id, appLog)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
