import React, {useState, useEffect} from 'react'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { accessTagService } from '../../_services'

const ApiCredentialForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        if (accessTags.length === 0) {
            accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (props.data.accessTag === '' && accessTags.length > 0) {
                    handleChange({target: {type: "select", name: "accessTag", value: accessTags[0].id}})
                }
            })
        }
        if (props.data.type === '') {
            handleChange({target: {type: "select", name: "type", value: 'TERACOM'}})
        }
    }, [])

    function addParam(e) {
        e.preventDefault()

        let newParams = {...state.params, '': ''}

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function removeParam(e, key) {
        e.preventDefault();

		let newParams = {...state.params}
        delete newParams[key]

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleKeyChange(e, key) {
        let targetValue = e.target.value

        var newParams = {}
        for (const [prevKey, prevValue] of Object.entries(state.params)) {
            console.log(prevKey + ': ' + prevValue)
            if (prevKey !== key) {
                newParams[prevKey] = prevValue
            } else {
                while (targetValue in state.params) {
                    targetValue = '_' + targetValue
                }
                newParams['' + targetValue] = prevValue
            }
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...state.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="accessTag">
                <Form.Label>
                    {t('apicredential.columns.accesstag')}
                </Form.Label>
                <Form.Control required as="select" name="accessTag" value={state.accessTag} onChange={handleChange}>
                    {accessTags.map(accessTag => (
                        <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('apicredential.columns.type')}
                </Form.Label>
                <Form.Control required as="select" name="type" value={state.type} onChange={handleChange}>
                    <option key="TERACOM" value="TERACOM">TERACOM</option>
                    <option key="THINGPARK" value="THINGPARK">THINGPARK</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="params">
                <Form.Label>
                    {t('apicredential.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(state.params).length > 0 &&
                        <thead>
                            <tr>
                                <th style={{width: '33%'}}>
                                    {t('apicredential.params.key')}
                                </th>
                                <th style={{width: '67%'}}>
                                    {t('apicredential.params.value')}
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {Object.entries(state.params).map(([key, value], index) => {
                            return <tr key={index}>
                                <td>
                                    <Form.Control required type="text" placeholder={t('apicredential.params.key')} name="key" value={key} onChange={(e) => handleKeyChange(e, key)} />
                                </td>
                                <td>
                                    <Form.Control required type="text" placeholder={t('apicredential.params.value')} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
                                </td>
                                <td>
                                    <Button
                                        onClick={(e) => removeParam(e, key)}
                                        title="Delete"
                                        size="sm"
                                        color="info"
                                        variant="outline-danger">
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <Button
                    onClick={addParam}
                    title={t('apicredential.params.addparam')}
                    color="info"
                    variant="outline-primary">
                    <FontAwesomeIcon icon={faPlus}/> {t('apicredential.params.addparam')}
                </Button>
            </Form.Group>
        </Form>
    )
}

export default ApiCredentialForm