import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { accessTagService, nodeService, sensorService, exportService } from '../../_services'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import { subMonths } from 'date-fns';
import DropdownContainer from '../dropdowncontainer.component'

function Export() {

	let _accessTagMap = useRef();
	let _sensorMap = useRef();
    let _nodeMap = useRef();

	const [state, setState] = useState({email: '', sensors: [], startDate: subMonths(new Date(), 6), endDate: new Date()});
	const [validated, setValidated] = useState(false);
	const [nodes, setNodes] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        registerLocale('sv', sv)
        if (nodes.length === 0) {
            downloadNodes()
        }
    }, [])

    const downloadNodes = () => {
        Promise.all([accessTagService.getAll(), sensorService.getAll(), nodeService.getAll()]).then((values) => {
            const [accessTags, sensors, _nodes] = values

            _accessTagMap.current = accessTags.reduce(function(map, obj) {
                map[obj.id] = obj;
                return map;
            }, {});

            _sensorMap.current = sensors.reduce(function(map, obj) {
                map[obj.id] = obj;
                return map;
            }, {});

            _nodeMap.current = _nodes.reduce(function(map, obj) {
                map[obj.id] = obj;
                return map;
            }, {});
            //const topNodes = _nodes.filter(node => node.parent === null)

            const topNodes = accessTags.map(accessTag => {
                console.log(accessTag)
                const children = _nodes.filter(node => node.accessTag == accessTag.id && node.parent === null)
                console.log(children)
                const childList = children.map( child => buildNodeTree(child.id, _nodeMap.current, _sensorMap.current))

                var allChildrenDisabled = childList.every(child => child.disabled)

                return {
		            label: accessTag.name,
		            value: accessTag.id,
		            type: 'accessTag',
		            children: childList,
		            checked: false,
		            disabled: allChildrenDisabled || childList.length == 0
		        }
            })
            setNodes(topNodes)

            //setNodes(topNodes.map( topNode => buildNodeTree(topNode.id, _nodeMap.current, _sensorMap.current)))
        });
    }

    const handleSubmit = (event) => {
        console.log('handleSubmit')
	    event.preventDefault();
	    const form = event.currentTarget;

	    if (state.sensors.length == 0) {
	        return
	    }

	    if (form.checkValidity() === false) {
			event.stopPropagation();
			setValidated(true);
	    } else {
			exportService.doExport(state).then(response => {
				console.log(response)
				setState({email: '', sensors: [], startDate: subMonths(new Date(), 6), endDate: new Date()})
				setNodes([])
				downloadNodes()
				alert(t('export.jobcreated'))
			})
			setValidated(false);
	    }
	};

    const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
	    }));
	}

	const handleStartDateChange = (date) => {
		setState( prevState => ({
	        ...prevState,
	        ['startDate']: date
	    }));
	}

	const handleEndDateChange = (date) => {
		setState( prevState => ({
	        ...prevState,
	        ['endDate']: date
	    }));
	    if (date < state.startDate) {
		    setState( prevState => ({
		        ...prevState,
		        ['startDate']: date
		    }));
	    }
	}

	const onSensorChange = (currentNode, selectedNodes) => {
        var sensorIds = []

        for (var selectedNode of selectedNodes) {
            if (selectedNode.type === 'sensor') {
                sensorIds.push(selectedNode.value) // Add id to list
            } else if (selectedNode.type === 'accessTag') {
				nodes.forEach(node => {
					if (node.value == selectedNode.value) {
						node.children.forEach(child => {
							handleNode(_nodeMap.current[child.value], _nodeMap.current, sensorIds)
						})
					}
				})
            } else {
                handleNode(_nodeMap.current[selectedNode.value], _nodeMap.current, sensorIds) // Traverse tree
            }
        }

		setState( prevState => ({
	        ...prevState,
	        sensors: sensorIds
	    }));
    }

    function handleNode(node, nodeMap, sensorIds) {
        for (var sensorId of nodeMap[node.id].sensors) {
            sensorIds.push(sensorId)
        }
        for (var childNode of nodeMap[node.id].children) {
            handleNode(nodeMap[childNode], nodeMap, sensorIds)
        }
    }

	function buildNodeTree(nodeId, nodeMap, sensorMap) {
        var node = nodeMap[nodeId]
        var childList = []
        if (node.children.length > 0) {
            childList = node.children.map(childId => buildNodeTree(childId, nodeMap, sensorMap))
            childList.sort((a, b) => a.label.localeCompare(b.label))
        }

		var sensorList = []
        for (var sensorId of node.sensors) {
            var sensor = sensorMap[sensorId]
            sensorList.push({
                label: sensor.name,
                value: sensor.id,
                type: 'sensor',
                checked: false
            })
        }
        if (sensorList.length > 0) {
            sensorList.sort((a, b) => a.label.localeCompare(b.label))
            childList.push(...sensorList)
        }

        var allChildrenDisabled = childList.every(child => child.disabled)

        return {
            label: node.name,
            value: node.id,
            type: 'node',
            children: childList,
            checked: false,
            disabled: allChildrenDisabled || childList.length == 0
        }
    }

	return (
		<div>
			<h2>
                {t('export.label')}
            </h2>
			<Col md={{ span: 3, offset: 0 }}>
				<Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
		            <Form.Group controlId="sensors">
		                <Form.Label>
		                    {t('export.sensors')}
		                </Form.Label>
		                <DropdownContainer
		                    data={nodes}
			                onChange={onSensorChange}
			                className="bootstrap-treeselect"
			                showPartiallySelected={true}
			            />
		            </Form.Group>
		            <Form.Group controlId="startDate">
		                <Form.Label>
		                    {t('export.startdate')}
		                </Form.Label>
		                <DatePicker
		                    selected={state.startDate}
		                    dateFormat="yyyy-MM-dd"
		                    locale="sv"
		                    onChange={handleStartDateChange}
		                    maxDate={state.endDate}
		                    className="form-control"
		                />
		            </Form.Group>
		            <Form.Group controlId="endDate">
		                <Form.Label>
		                    {t('export.enddate')}
		                </Form.Label>
		                <DatePicker
		                    selected={state.endDate}
		                    dateFormat="yyyy-MM-dd"
		                    locale="sv"
		                    onChange={handleEndDateChange}
		                    maxDate={new Date()}
		                    className="form-control"
		                />
		            </Form.Group>
		            <Form.Group controlId="email">
		                <Form.Label>
		                    {t('export.email')}
		                </Form.Label>
		                <Form.Control
		                    type="email"
		                    placeholder={t('export.email')}
		                    name="email"
		                    value={state.email}
		                    onChange={(e) => handleChange(e)}
		                    required/>
		            </Form.Group>
	            </Form>
	            <Button form='data-form' type="submit" variant="primary">
		            {t('export.export')}
		        </Button>
            </Col>
        </div>
    )
}

export default Export;