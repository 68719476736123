import React, {useState} from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { useTranslation } from "react-i18next";

function EditButton(props) {

    const [show, setShow] = useState(false)
    const {t, i18n} = useTranslation('common');

    function handleConfirm (data) {
        setShow(false)
        props.confirmClick(data)
    }

    return (
        <>
        <Button 
            onClick={() => setShow(true)}
            title="Edit" 
            size="sm" 
            color="info" 
            variant="outline-secondary">
            <FontAwesomeIcon icon={faEdit}/>
        </Button>
        <ModalForm
            data={props.data}
            show={show}
            handleConfirm={handleConfirm}
            handleClose={() => setShow(false)} 
            modalHeader={t('crudtable.edit')}
            modalBody={props.form}
            size={props.size}
        />
        </>
    )

}

export default EditButton