import React, {useState, useEffect} from 'react'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { accessTagService, networkService, deviceModelService, environmentService } from '../../_services'

const DeviceForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const [networks, setNetworks] = useState([])
    const [deviceModels, setDeviceModels] = useState([])
    const [environment, setEnvironment] = useState('development')
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
		const existingEnvironment = environmentService.loadEnvironment()
        setEnvironment(existingEnvironment)

        if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (!props.data.accessTag && accessTags.length > 0) {
                    handleChange({target: {name: "accessTag", value: accessTags[0].id}})
                }
            })
		}

		if (existingEnvironment != 'production_wexnet') {
			networkService.getAll(props.data.networkType).then(networks => {
                setNetworks(networks)
	            if (networks.indexOf(props.data.network) < 0 && networks.length > 0) {
	                handleChange({target: {type: "select", name: "network", value: networks[0]}})
	            }
	        })
        } else {
            setTimeout(() => {
                handleChange({target: {type: "select", name: "networkType", value: 'LORAWAN'}})
                handleChange({target: {type: "select", name: "network", value: 'STADSHUBBSALLIANSEN'}})
            }, 100)
        }

        deviceModelService.getAll().then(deviceModels => {
            setDeviceModels(deviceModels)
            if (!props.data.model && deviceModels.length > 0) {
                handleChange({target: {name: "model", value: deviceModels[0].id}})
            }
        })
    }, [])

    function addParam(e) {
        e.preventDefault()

        let newParams = {...state.params, '': ''}

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function removeParam(e, key) {
        e.preventDefault();

		let newParams = {...state.params}
        delete newParams[key]

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleKeyChange(e, key) {
        let targetValue = e.target.value

        var newParams = {}
        for (const [prevKey, prevValue] of Object.entries(state.params)) {
            console.log(prevKey + ': ' + prevValue)
            if (prevKey !== key) {
                newParams[prevKey] = prevValue
            } else {
                while (targetValue in state.params) {
                    targetValue = '_' + targetValue
                }
                newParams['' + targetValue] = prevValue
            }
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...state.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }
    
    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="accessTag">
                <Form.Label>
                    {t('device.columns.accesstag')} *
                </Form.Label>
                <Form.Control as="select" name="accessTag" value={state.accessTag} onChange={handleChange} required>
                    { accessTags.map(accessTag => {
                        return <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('device.columns.name')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('device.columns.name')} name="name" value={state.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="ref">
                <Form.Label>
                    {t('device.columns.ref')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('device.columns.ref')} name="ref" value={state.ref} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="deviceModel">
                <Form.Label>
                    {t('device.columns.devicemodel')} *
                </Form.Label>
                <Form.Control required as="select" placeholder={t('device.columns.devicemodel')} name="model" value={state.model} onChange={handleChange}>
                    { deviceModels.map(deviceModel => {
                        return <option value={deviceModel.id} key={deviceModel.id}>{deviceModel.modelName} ({deviceModel.brandName})</option>
                    })}
                </Form.Control>
            </Form.Group>
            { environment != 'production_wexnet' &&
                <>
		            <Form.Group controlId="networkType">
		                <Form.Label>
		                    {t('device.columns.networktype')}
		                </Form.Label>
		                <Form.Control required as="select" placeholder={t('device.columns.networktype')} name="networkType" value={state.networkType} onChange={handleChange}>
		                    <option>LORAWAN</option>
		                    <option value="NB_IOT">NB-IoT</option>
		                </Form.Control>
		            </Form.Group>
		            <Form.Group controlId="network">
		                <Form.Label>
		                    {t('device.columns.network')}
		                </Form.Label>
		                <Form.Control required as="select" placeholder={t('device.columns.network')} name="network" value={state.network} onChange={handleChange}>
		                    { networks.map(network => {
		                        return <option key={network}>{network}</option>
		                    })}
		                </Form.Control>
		            </Form.Group>
	            </>
            }
            <Form.Group controlId="params">
                <Form.Label>
                    {t('device.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(state.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th style={{width: '33%'}}>
	                                {t('device.params.key')}
	                            </th>
	                            <th style={{width: '67%'}}>
	                                {t('device.params.value')}
	                            </th>
	                            <th></th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(state.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									<Form.Control required type="text" placeholder={t('device.params.key')} name="key" value={key} onChange={(e) => handleKeyChange(e, key)} />
								</td>
								<td>
									<Form.Control required type="text" placeholder={t('device.params.value')} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
								</td>
	                            <td>
									<Button
			                            onClick={(e) => removeParam(e, key)}
			                            title="Delete"
			                            size="sm"
			                            color="info"
			                            variant="outline-danger">
			                            <FontAwesomeIcon icon={faTrash}/>
			                        </Button>
		                        </td>
							</tr>
		                })}
	                </tbody>
                </table>
                <Button
                    onClick={addParam}
                    title={t('device.params.addparam')}
                    color="info"
                    variant="outline-primary">
                    <FontAwesomeIcon icon={faPlus}/> {t('device.params.addparam')}
                </Button>
            </Form.Group>
        </Form>
    )
}

export default DeviceForm