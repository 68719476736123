import React, {useState, useEffect} from 'react';
import { nodeService, sensorService, authService, iconService, accessTagService } from '../../_services'
import NodeForm from './nodeform.component'
import SensorForm from './sensorform.component'
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap'
import AddButton from './addbutton';
import AddNodeButton from './addnodebutton';
import EditButton from './editbutton';
import DeleteButton from './deletebutton';
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import * as moment from 'moment'
import { Link } from 'react-router-dom'
import './node.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faFolder, faEdit, faFolderOpen, faFolderPlus, faCity, faRoad, faDrawPolygon, faPlus, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons'
import { IfAnyGranted, IfAllGranted } from '../permission';

function Node() {

	const [nodes, setNodes] = useState([]);
	const [nodesDownloaded, setNodesDownloaded] = useState(false);
	const [sensors, setSensors] = useState([]);
	const [sensorsDownloaded, setSensorsDownloaded] = useState(false);
	const [openNodes, setOpenNodes] = useState([]);
	const [openNodesLoaded, setOpenNodesLoaded] = useState(false);
	const [accessTags, setAccessTags] = useState([])
	const [accessTagsDownloaded, setAccessTagsDownloaded] = useState(false);
	const {t} = useTranslation('common');
	const dispatch = useDispatch();
	const childPadding = 20

	const initialNodeState = {
		accessTag: '',
		parent: null,
		name: '',
		description: '',
		type: 'NONE',
		params: {}
	}

	const initialSensorState = {
		accessTag: '',
		parent: null,
		name: '',
		description: '',
		type: 'TEMPERATURE',
		icon: '',
		marker: false,
		lat: 56.888523,
		lng: 14.800766,
		updatable: false,
		params: {},
		deviceRefs: [],
		public_: false,
		paused: false
	}

	useEffect(() => {
		if (!openNodesLoaded) {
			var user = authService.getCurrentUser()
            var key = "openNodes-" + user.id
			if (localStorage.getItem(key)) {
				setOpenNodes(JSON.parse(localStorage.getItem(key)))
				setOpenNodesLoaded(true)
			}
		}
		if (!nodesDownloaded) {
			setNodesDownloaded(true)
			updateNodes().then(data => {
				console.log('nodes updated')
			})
		}
		if (!sensorsDownloaded) {
			setSensorsDownloaded(true)
			updateSensors().then(data => {
				console.log('sensors updated')
			})
		}
		if (!accessTagsDownloaded) {
			setAccessTagsDownloaded(true)
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
            })
		}
    }, [nodes, sensors, openNodes, accessTags])

	const updateNodes = () => {
		return new Promise((resolve, reject) => {
			nodeService.getAll().then(data => {
				data.map(node => {
					node.tempChildren = data.filter(otherNode => otherNode.parent === node.id)
				})
	            setNodes(data)
	            resolve(data)
	        })
        })
    }

    const updateSensors = () => {
		return new Promise((resolve, reject) => {
			sensorService.getAll().then(data => {
	            setSensors(data)
	            resolve(data)
	        })
        })
    }

    const getRoots = () => {
        return nodes.filter(node => node.parent === null).sort((a, b) => a.name.localeCompare(b.name))
    }

    const getChildren = (parent) => {
        return nodes.filter(node => node.parent === parent.id).sort((a, b) => a.name.localeCompare(b.name))
    }

    const getSensors = (parent) => {
        return sensors.filter(sensor => sensor.parent === parent.id).sort((a, b) => a.name.localeCompare(b.name))
    }

	const toggleNode = (node) => {
		const newOpenNodes = []
		openNodes.map(nodeId => {
			newOpenNodes.push(nodeId)
		})
		if (newOpenNodes.indexOf(node.id) > -1) {
			newOpenNodes.splice(newOpenNodes.indexOf(node.id), 1)
		} else {
			newOpenNodes.push(node.id)
		}
		setOpenNodes(newOpenNodes)
		saveOpenNodes(newOpenNodes)
	}

	const getChevron = (node) => {
		if (openNodes.indexOf(node.id) > -1) {
            return <FontAwesomeIcon icon={faChevronUp} style={{width: childPadding}}/>
        }
        return <FontAwesomeIcon icon={faChevronDown} style={{width: childPadding}}/>
    }

	const closeAll = () => {
        setOpenNodes([])
        saveOpenNodes([])
	}

    const openAll = () => {
  	    const newOpenNodes = []
        nodes.map(node => {
            if (node.children.length > 0) {
                newOpenNodes.push(node.id)
            }
        })
        setOpenNodes(newOpenNodes)
        saveOpenNodes(newOpenNodes)
    }

    const onAddNode = (node) => {
        console.log(node)

        if (node.parent && openNodes.indexOf(node.parent) < 0) {
            setOpenNodes([...openNodes, node.parent])
        }

		nodeService.save(node).then(addedNode => {
			dispatch(alertActions.success(t('alerts.createsuccessful')))
			updateNodes()
        })
    }

    const onAddSensor = (sensor) => {
        console.log(sensor)

        if (sensor.parent && openNodes.indexOf(sensor.parent) < 0) {
            setOpenNodes([...openNodes, sensor.parent])
        }

		sensorService.save(sensor).then(addedSensor => {
			dispatch(alertActions.success(t('alerts.createsuccessful')))

			updateNodes()
			updateSensors()
        })
    }

    const onEditNode = (node) => {
        console.log(node)

        nodeService.update(node.id, node).then(updatedNode => {
            dispatch(alertActions.success(t('alerts.updatesuccessful')))
            updateNodes()
        })
    }

    const onEditSensor = (sensor) => {
        console.log(sensor)

        sensorService.update(sensor.id, sensor).then(updatedSensor => {
            dispatch(alertActions.success(t('alerts.updatesuccessful')))
            updateSensors()
        })
    }

    const onDeleteNode = (node) => {
        console.log(node)

		nodeService._delete(node.id).then(data => {
			dispatch(alertActions.success(t('alerts.deletesuccessful')))
            updateNodes()
        })
    }

    const onDeleteSensor = (sensor) => {
        console.log(sensor)

		sensorService._delete(sensor.id).then(data => {
			dispatch(alertActions.success(t('alerts.deletesuccessful')))
            updateSensors()
        })
    }

    const formatDate = (date) => {
		return moment(date).format('YYYY-MM-DD HH:mm')
    }

    const saveOpenNodes = (nodes) => {
        var user = authService.getCurrentUser()
        var key = "openNodes-" + user.id
        localStorage.setItem(key, JSON.stringify(nodes))
    }

    const getNodeIcon = (node) => {
        switch (node.type) {
            case 'GEOGRAPHICAL_CITY':
                return <FontAwesomeIcon icon={faCity} style={{width: 25}}/>
            case 'GEOGRAPHICAL_DISTRICT':
                return <FontAwesomeIcon icon={faDrawPolygon} style={{width: 25}}/>
            case 'GEOGRAPHICAL_ROAD':
                return <FontAwesomeIcon icon={faRoad} style={{width: 25}}/>
            case 'FIWARE_BEACH':
                return <FontAwesomeIcon icon={faUmbrellaBeach} style={{width: 25}}/>
        }
        return <FontAwesomeIcon icon={faFolder} style={{width: 25}}/>
    }

    const formatAccessTag = function(accessTagId) {
        var accessTag = accessTags.find((accessTag) => accessTag.id == accessTagId)
        if (accessTag) {
			return accessTag.name
        }
        return accessTagId
    }

    function NodeRow(props) {
        const node = props.node
        const depth = props.depth
        const children = node.tempChildren
        const sensors = getSensors(node)
        const open = openNodes.indexOf(node.id) > -1
        return <>
            <tr key={'node_' + node.id}>
                <td>
	                { (children.length > 0 || sensors.length > 0) &&
	                    <>
		                    <a className="chevron" style={{paddingLeft: depth * childPadding, cursor: 'pointer'}} onClick={() => toggleNode(node)}>
		                        {getChevron(node)}
		                    </a>
		                    {getNodeIcon(node)}
		                    <span>{node.name}</span>
	                    </>
	                }
	                { (children.length === 0 && sensors.length === 0) &&
	                    <>
	                        <span style={{paddingLeft: depth * childPadding}}></span>
	                        <FontAwesomeIcon icon={faChevronDown} style={{color: '#CCCCCC', width: childPadding}}/>
	                        {getNodeIcon(node)}
	                        <span>{node.name}</span>
	                    </>
	                }
	            </td>
	            <td>
	                {node.description}
	            </td>
	            <td>
	                {node.type}
	            </td>
	            <td>
	                {formatAccessTag(node.accessTag)}
	            </td>
	            <IfAnyGranted roles={["ROLE_NODE_WRITE", "ROLE_SENSOR_WRITE"]}>
		            <td className="buttons">
		                <IfAllGranted roles={["ROLE_NODE_WRITE"]}>
			                <AddButton
								data={{...initialNodeState, parent: node.id, accessTag: node.accessTag}}
								confirmClick={onAddNode}
								form={<NodeForm />}
								icon={faFolderPlus}/>
			                <span>&nbsp;</span>
			            </IfAllGranted>
		                <IfAllGranted roles={["ROLE_SENSOR_WRITE"]}>
			                <AddButton
								data={{...initialSensorState, parent: node.id, accessTag: node.accessTag}}
								confirmClick={onAddSensor}
								form={<SensorForm />}
								icon={faPlus}
								size="xl"/>
			                <span>&nbsp;</span>
			            </IfAllGranted>
			            <IfAllGranted roles={["ROLE_NODE_WRITE"]}>
			                <EditButton
								data={node}
								confirmClick={onEditNode}
								form={<NodeForm />}
								icon={faEdit}/>
			                <span>&nbsp;</span>
			                <DeleteButton
			                    data={node}
			                    confirmClick={onDeleteNode}/>
		                </IfAllGranted>
		            </td>
	            </IfAnyGranted>
	        </tr>
	        { open &&
	            <>
		            { children.map((child) => {
		                return <NodeRow key={child.id} node={child} depth={depth+1}/>
		            })}
		            { sensors.map((sensor) => {
	                    return <SensorRow key={sensor.id} sensor={sensor} depth={depth+1}/>
	                })}
	            </>
	        }
        </>
	}

	function SensorRow(props) {
		const sensor = props.sensor
		const depth = props.depth
		return <tr key={'sensor' + sensor.id}>
			<td>
				<span style={{paddingLeft: (depth + 1) * childPadding}}></span>
				{iconService.getIcon(sensor)}
				<span className="name" title={sensor.id}>
					{sensor.name}
				</span>
			</td>
			<td>
				<span title={sensor.description} style={{display: 'block', maxWidth: '500px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {sensor.description}
                </span>
            </td>
            <td>
                {sensor.type}
            </td>
            <td>
                {formatAccessTag(sensor.accessTag)}
            </td>
            <IfAnyGranted roles={["ROLE_NODE_WRITE", "ROLE_SENSOR_WRITE"]}>
                <td className="buttons">
                    <IfAllGranted roles={["ROLE_SENSOR_WRITE"]}>
						<>
							<EditButton
								data={sensor}
								confirmClick={onEditSensor}
								form={<SensorForm />}
								icon={faEdit}
								size="xl"/>
							<span>&nbsp;</span>
			                <DeleteButton
			                    data={sensor}
			                    confirmClick={onDeleteSensor}/>
		                </>
	                </IfAllGranted>
				</td>
            </IfAnyGranted>
		</tr>
    }

    return (
        <div>
            <h2>
                {t('node.label')}
            </h2>
            <div>
	            <div className="float-left" style={{marginBottom: "0.5rem"}}>
		            <Button
		                onClick={openAll}
		                title={t("node.openall")}
		                size="sm"
		                color="info"
		                variant="outline-secondary">
		                {t('node.openall')} <FontAwesomeIcon icon={faFolderOpen}/>
		            </Button>
		            <span>&nbsp;</span>
		            <Button
		                onClick={closeAll}
		                title={t("node.closeall")}
		                size="sm"
		                color="info"
		                variant="outline-secondary">
		                {t('node.closeall')} <FontAwesomeIcon icon={faFolder}/>
		            </Button>
		        </div>
		        <div className="float-right">
		            <IfAllGranted roles={["ROLE_NODE_WRITE"]}>
                        <AddNodeButton
				            data={{...initialNodeState, parent: null, accessTag: ''}}
				            confirmClick={onAddNode}
				            form={<NodeForm />}
				            size="large"/>
                    </IfAllGranted>
		        </div>
	        </div>
            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th>
                            {t('node.columns.name')}
                        </th>
                        <th>
                            {t('node.columns.description')}
                        </th>
                        <th className="type">
                            {t('node.columns.type')}
                        </th>
                        <th className="accessTag">
                            {t('node.columns.accesstag')}
                        </th>
                        <IfAnyGranted roles={["ROLE_NODE_WRITE", "ROLE_SENSOR_WRITE"]}>
                            <th></th>
                        </IfAnyGranted>
                    </tr>
                </thead>
                <tbody>
                    { getRoots().length === 0 &&
                        <tr>
                            <td colSpan="5" style={{textAlign: 'center'}}>
								{t('node.nonodes')}
                            </td>
                        </tr>
                    }
					{ getRoots().map((node) => {
						return <NodeRow node={node} depth={0} key={node.id}/>
					})}
				</tbody>
	        </table>
        </div>
    )
}

export default Node;