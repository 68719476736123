import React from 'react'
import {authService} from '../_services/'

const Access = ({ children, permission, products }) => {
    const hasPermission = authService.hasAnyRole(permission)
    var hasProducts = true
    if (products && products.length > 0) {
        hasProducts = authService.hasProducts(products)
    }

    return (
      <>
        {hasPermission && hasProducts && children}
      </>
    );
  };

export default Access