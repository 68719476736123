import React, {useState, useEffect} from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { customerService, accessTagService } from '../../_services'
import moment from 'moment'

const CustomerAccessTagForm = ({data, handleChange, handleSubmit, validated}) => {
    const [customers, setCustomers] = useState([])
    const [accessTags, setAccessTags] = useState([])

    const {t} = useTranslation('common');

    useEffect(() => {
        customerService.getAll().then(customers => {
            setCustomers(customers)
            if (data.customer === -1 && customers.length > 0) {
                handleChange({target: {type: "select", name: "customer", value: customers[0].id}})
            }
        })
        accessTagService.getAll().then(accessTags => {
            setAccessTags(accessTags)
            if (data.accessTag === -1 && accessTags.length > 0) {
                handleChange({target: {type: "select", name: "accessTag", value: accessTags[0].id}})
                console.log(accessTags[0].id)
            }
        })
    }, [])

    const addTenYears = function() {
        var newDate = moment(data.expireDate).add(10, 'years').format('YYYY-MM-DD')
        handleChange({target: {type: "date", name: "expireDate", value: newDate}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="customer">
                <Form.Label>
                    {t('customeraccesstag.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customer" value={data.customer} onChange={handleChange} disabled={data.id}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="accessTagId">
                <Form.Label>
                    {t('customeraccesstag.columns.accesstag')}
                </Form.Label>
                <Form.Control required as="select" name="accessTag" value={data.accessTag} onChange={handleChange} disabled={data.id}>
                    {accessTags.map(accessTag => (
                        <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="expireDate">
                <Form.Label>
	                {t('customeraccesstag.columns.expiredate')}
	            </Form.Label>
                <Form.Row>
                    <Col>
		                <Form.Control required as="input" type="date" name="expireDate" value={moment(data.expireDate).format('YYYY-MM-DD')} onChange={handleChange}/>
		            </Col>
		            <Col xs="auto">
		                <Button
		                    onClick={addTenYears}
		                    title={t('customeraccesstag.addtenyears')}
		                    color="info"
		                    variant="outline-primary">
		                    <FontAwesomeIcon icon={faPlus}/> {t('customeraccesstag.addtenyears')}
		                </Button>
	                </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group controlId="owner">
                <Form.Check type="checkbox" label={t('customeraccesstag.columns.owner')} name="owner" checked={data.owner} onChange={handleChange} />
            </Form.Group>
        </Form>
    )
}


export default CustomerAccessTagForm