import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { customerService } from '../../_services'
import CustomerForm from './customerform.component'
import { useTranslation } from "react-i18next";

function Customer() {

    const {t} = useTranslation('common');

	const initialState = {
		name: '',
		customerAccessTags: [],
		products: []
	}

	const formatCustomerAccessTags = function(cell) {
		if (cell && cell.length > 0) {
			return cell.map(customerAccessTag => customerAccessTag.accessTagName).join(', ')
		}
		return '-'
	}

	const formatProducts = function(cell) {
		if (cell && cell.length > 0) {
			return cell.map(product => product.name).join(', ')
		}
		return '-'
	}

	const columns = [
		{
			dataField: 'name',
			text: t('customer.columns.name'),
			sort: true
		},
		{
			dataField: 'customerAccessTags',
			text: t('customer.columns.accesstags'),
			formatter: formatCustomerAccessTags
		},
		{
			dataField: 'products',
			text: t('customer.columns.products'),
			formatter: formatProducts
		}
	]

  return (
    <div>
      <h2>
        {t('customer.label')}
      </h2>
      <CrudTable
        service={customerService}
        columns={columns}
        initialState={initialState}
        form={<CustomerForm />}
        sort={{dataField: 'name', order: 'asc'}}
      />
      </div>
  )
}

export default Customer;