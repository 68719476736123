import React from 'react';
import CrudTable from '../table/crudtable'
import { adminAccessTagService } from '../../_services'
import AccessTagForm from './accesstagform.component'
import { useTranslation } from "react-i18next";

function AccessTag() {

  const {t} = useTranslation('common');

  const initialState = {
      name: '',
      active: true
  }

    const formatBoolean = function(cell) {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const columns = [
		{
			dataField: 'name',
			text: t('accesstag.columns.name'),
			sort: true
		},
		{
			dataField: 'id',
			text: t('accesstag.columns.id'),
			sort: true
		},
		{
			dataField: 'active',
			text: t('accesstag.columns.active'),
            formatter: formatBoolean,
            sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('accesstag.label')}
      </h2>
      <CrudTable
        service={adminAccessTagService}
        columns={columns}
        initialState={initialState}
        form={<AccessTagForm />}
        sort={{dataField: 'name', order: 'asc'}}
      />
    </div>
  )
}

export default AccessTag;