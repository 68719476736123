import React, {useState, useEffect} from 'react'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { accessTagService, deviceModelService, environmentService } from '../../_services'

const DeviceMetadataForm = (props) => {

    const state = props.data
    const [accessTags, setAccessTags] = useState([])
    const [deviceModels, setDeviceModels] = useState([])
    const [environment, setEnvironment] = useState('development')
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        console.log('useEffect')
        let mounted = true

        const existingEnvironment = environmentService.loadEnvironment()
        setEnvironment(existingEnvironment)

        if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
                if (state.accessTag == '') {
                    handleChange({target: {name: "accessTag", value: accessTags[0].id}})
                }
            })
		}

        deviceModelService.getAll().then(deviceModels => {
            setDeviceModels(deviceModels)
            if (state.model == '') {
                handleChange({target: {name: "model", value: deviceModels[0].id}})
            }
        })

        return function cleanup() {
            mounted = false
        }
    }, [])

    function handleNetworkTypeChange(e) {
        e.preventDefault()

		var newParams = {}
        if (e.target.value == 'LORAWAN') {
			newParams.devEUI = ''
			newParams.defaultAppKey = ''
			newParams.defaultAppEUI = ''
			newParams.serialNumber = ''
        } else {

        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
        handleChange({target: {type: "select", name: "networkType", value: e.target.value}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...state.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }
    
    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={props.validated}>
            <Form.Group controlId="accessTag">
                <Form.Label>
                    {t('devicemetadata.columns.accesstag')}
                </Form.Label>
                <Form.Control as="select" name="accessTag" value={state.accessTag} onChange={handleChange} required>
                    { accessTags.map(accessTag => {
                        return <option key={accessTag.id} value={accessTag.id}>{accessTag.name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="model">
                <Form.Label>
                    {t('devicemetadata.columns.model')}
                </Form.Label>
                <Form.Control required as="select" placeholder={t('devicemetadata.columns.model')} name="model" value={state.model} onChange={handleChange}>
                    { deviceModels.map(deviceModel => {
                        return <option value={deviceModel.id} key={deviceModel.id}>{deviceModel.modelName} ({deviceModel.brandName})</option>
                    })}
                </Form.Control>
            </Form.Group>
            { environment != 'production_wexnet' &&
	            <Form.Group controlId="networkType">
	                <Form.Label>
	                    {t('devicemetadata.columns.networktype')}
	                </Form.Label>
	                <Form.Control required as="select" placeholder={t('devicemetadata.columns.networktype')} name="networkType" value={state.networkType} onChange={handleNetworkTypeChange}>
	                    <option>LORAWAN</option>
	                    <option value="NB_IOT">NB-IoT</option>
	                </Form.Control>
	            </Form.Group>
            }
            <Form.Group controlId="params">
                <Form.Label>
                    {t('devicemetadata.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(state.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th style={{width: '33%'}}>
	                                {t('device.params.key')}
	                            </th>
	                            <th style={{width: '67%'}}>
	                                {t('device.params.value')}
	                            </th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(state.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									{key}
								</td>
								<td>
									<Form.Control type="text" placeholder={t('device.params.value')} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
								</td>
							</tr>
		                })}
	                </tbody>
                </table>
            </Form.Group>
        </Form>
    )
}

export default DeviceMetadataForm